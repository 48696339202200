<template>
  <div class="tc-suggestions">
    <div class="heading-block">
      <div class="heading-block__main">Brand compliance suggestions</div>
      <div class="heading-block__sub">Powered by ASTRID™ AI</div>
    </div>
    <div class="suggest-block" v-if="complyDetails && !convertFail">
      <p
        class="suggest-block__summary-text"
        v-if="!getDeckFullComplianceInfo && getComplianceScore !== 100"
      >
        We have {{ getSuggestionCount }} suggestions based on brand guidelines
        for your selected template.
      </p>
      <!-- <p class="suggest-block__summary-text" v-else>
        We have 0 suggestions based on brand guidelines for your selected
        template.
      </p> -->
    </div>

    <div v-if="convertFail" class="error-container">
      <img src="/assets/img/compliance-check-failed.svg" alt />
      <br /><br />
      <div class="error-text">
        Oops! Compliance check failed. Please try again
      </div>
      <div class="try-again-btn">
        <v-btn
          rounded
          color="primary"
          class="app-button"
          @click="handleTryAgain"
        >
          Try again
        </v-btn>
      </div>
    </div>
    <div v-else-if="!isLoading" class="tc-body">
      <ComplyScore
        v-if="rightPanelComponent === 'complyScore'"
        :fullDeckChanges="handleFullDeckSuggestionsChange"
        :applyDeckChanges="handleCategorySuggestionsChange"
        :reviewDetails="handleReviewDetails"
        :updateIncludeImageConversion="handleUpdateIncludeImageConversion"
        :changeStep="changeStep"
        :origin="origin"
      />

      <ComplyDeckSuggestions
        :fullDeckChanges="handleFullDeckSuggestionsChange"
        :applyDeckChanges="handleCategorySuggestionsChange"
        :reviewDetails="handleReviewDetails"
        :changeStep="changeStep"
        :origin="origin"
        v-if="rightPanelComponent === 'complySlideDetails'"
      />
    </div>
    <div v-else>
      <clip-loader color="#21a7e0" :width="20" :height="20" />
    </div>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="'Cancel'"
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  fetchSlidesData,
  reviewDetails,
  getSlideType,
} from '../../../ComplianceChecker/utils/comply-helper.utils';
import ComplyScore from './ComplyScore.vue';
import ComplyDeckSuggestions from './ComplyDeckSuggestions.vue';
import {
  deckFullCompliance,
  downloadConvertTemplateDeck,
  getSuggestionsTotalCount,
  postFeatureUsage,
  updateBestPractices,
} from '../../../../../utils/api-helper';
import ConfirmationDialog from '../../../../common/ConfirmationDialog.vue';
import { callMergeSlidesApi } from '../../../../../utils/merge-slide-helper';
import { trackPrezentationEvents } from '../../../../common/Analytics/PrezentationEvents';
import { trackComplyEvents } from '../../../../common/Analytics/ComplyEvents';
import {
  MY_PREZENTATION,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
  TD_NUMBEROFSLIDES,
  TD_PREZENTATION_ORIGIN,
  TD_PREZENTATION_COMPANY_SOURCE,
  TD_PREZENTATION_LIBRARY,
  TD_CROSS_FEATURE,
  TD_PLACEMENT,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_CATEGORY_ACCEPTED,
  TD_DECK,
  BUILD,
  TD_TIME_SPENT_IN_SECS,
  TD_MAKE_100_PERCENT_COMPLIANCE_ACCEPT_ALL_SUGGESTIONS,
  TD_MAKE_100_PERCENT_COMPLIACE,
} from '../../../../common/Analytics/MatomoTrackingDataHelper';
import {
  getS3Bucket,
  getTotalSuggestionsInSlide,
} from '../../../../../utils/common';

export default {
  components: {
    ComplyScore,
    ComplyDeckSuggestions,
    ClipLoader,
    ConfirmationDialog,
  },
  data() {
    return {
      isLoading: false,
      rightPanelComponent: 'complyScore',
      signal: new AbortController(),
      imageConversion: false,
      currTab: 'deck',
      convertFail: false,
      showConfirmation: false,
      dialogTitle: 'Convert template',
      dialogText:
        'Your prezentation will be converted based on the selected template.',
      dialogPrimary: 'Convert template',
      suggestionFunctionParameters: {},
    };
  },
  props: {
    uploadId: {
      type: String,
      default: '',
    },
    selectedTemplate: {
      type: Object,
      default: null,
    },
    updatePrezentation: {
      type: Function,
      default: () => {},
    },
    changeStep: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      required: false,
      default: MY_PREZENTATION,
    },
  },
  beforeDestroy() {
    this.signal?.abort();
  },
  computed: {
    ...mapState('prezentationDetails', ['prezentationData', 'complyDetails']),
    ...mapState('users', ['currentUser']),
    ...mapGetters('prezentationDetails', ['isPrezentSourceBestPractice']),

    getDeckFullComplianceInfo() {
      return this.complyDetails?.compliance_score === 100;
    },
    getComplianceScore() {
      return this.complyDetails?.compliance_score;
    },
    getSuggestionCount() {
      return this.complyDetails?.intial_suggestion_count;
    },
  },
  methods: {
    ...mapActions('prezentationDetails', [
      'setLoadingAnimations',
      'setComplyDetails',
      'setPrezentationData',
      'updateActionCompletedInfo',
    ]),
    ...mapActions('users', ['setLimitData']),
    capitalizeFirstLetter(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    handleTryAgain() {
      this.convertFail = false;
      this.changeStep();
    },
    getS3dir() {
      if (this.isPrezentSourceBestPractice) {
        return `protected/premium-feature/tc/${this.prezentationData.id}`;
      }

      return this.prezentationData.s3dirpath
        ? (this.prezentationData.s3dirpath.indexOf('private/') === -1
            ? `private/${this.prezentationData.s3dirpath}`
            : this.prezentationData.s3dirpath
          )
            .split('/')
            .filter((t) => t)
            .join('/')
        : `private/prezentation/${this.prezentationData.id}`;
    },
    onDialogCancel() {
      this.showConfirmation = false;
    },
    onDialogConfirm() {
      this.showConfirmation = false;
      const { categorySelected, action, isFromSuggestions } =
        this.suggestionFunctionParameters;
      if (action) {
        this.categorySuggestionsChange(categorySelected, action);
      } else {
        this.fullDeckSuggestionsChange(isFromSuggestions);
      }
    },
    handleCategorySuggestionsChange(categorySelected, action) {
      if (this.isPrezentSourceBestPractice) {
        this.categorySuggestionsChange(categorySelected, action);
      } else if (action === 'accept') {
        this.showConfirmation = true;
        this.suggestionFunctionParameters = { categorySelected, action };
      } else if (action === 'reject') {
        this.categorySuggestionsChange(categorySelected, action);
      }
    },
    async categorySuggestionsChange(categorySelected, action) {
      console.log('Inside action with category:', categorySelected);
      let countOfSlides = 0;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      const eventStartTime = new Date();
      this.convertFail = false;
      this.signal = new AbortController();
      this.setLoadingAnimations({
        isLoading: true,
        animationsData: {
          showBlast: false,
          type: 'acceptSuggestionLoader',
        },
      });
      let deckData = {
        reference_template: this.selectedTemplate?.template_internal_name,
        upload_id: this.uploadId,
        category: [categorySelected],
        source:
          Object.keys(this.complyDetails?.slide_images).length > 0
            ? 'template-conversion'
            : '',
        action,
        s3Dir: this.getS3dir(),
      };

      if (deckData.category[0].toLowerCase() === 'images') {
        deckData = {
          ...deckData,
          ...{ include_image_conversions: action.toLowerCase() === 'accept' },
        };
      } else if (
        deckData.category[0].toLowerCase() === 'color' &&
        action.toLowerCase() === 'accept'
      ) {
        deckData = {
          ...deckData,
          applyBGtoFont: [
            'bg_to_font_color',
            'bg_to_bullet_color',
            'bg_to_svg_color',
          ],
        };
      }
      try {
        await deckFullCompliance(deckData);

        const slidesData = await fetchSlidesData(
          this.uploadId,
          this.signal.signal,
        );
        if (action === 'accept') {
          const otherData = {
            [TD_AUD]: this.prezentationData?.audienceID,
            [TD_PREZNAME]: this.prezentationData.name,
            [TD_PREZTYPE]: this.prezentationData?.type,
            [TD_TEMPLATE]: this.prezentationData?.theme,
            [TD_NUMBEROFSLIDES]: countOfSlides,
            [TD_PREZENTATION_ORIGIN]:
              this.prezentationData?.prezentationSource ||
              this.prezentationData?.prezentationType,
            [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
            [TD_CROSS_FEATURE]:
              this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
            [TD_PLACEMENT]: TD_DECK,
            [TD_COMMON_COLUMN_NAME]: TD_CATEGORY_ACCEPTED,
            [TD_COMMON_COLUMN_VALUE]:
              this.capitalizeFirstLetter(categorySelected),
            [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
          };
          trackComplyEvents.complianceCheckerReviewDetailsCategoryAccept(
            this.currentUser,
            otherData,
          );
        }
        const prevSlideInfo = this.complyDetails;

        if (slidesData?.status === 'error') {
          this.convertFail = true;
          this.setLoadingAnimations({
            isLoading: false,
            animationsData: {},
          });
          return;
        }

        postFeatureUsage({ feature: 'comply' })
          .then((resp) => {
            if (resp?.data) {
              this.setLimitData(resp.data);
            }
          })
          .catch((error) => console.log(error));
        // comparing with previous state to determine the user action to resolve all the suggested changes https://prezentium.atlassian.net/browse/PM-6729
        if (slidesData) {
          switch (categorySelected) {
            case 'color':
              slidesData.color_suggestions_count_resolved =
                prevSlideInfo.color_suggestions_count !== 0
                  ? slidesData.color_suggestions_count === 0
                  : false;
              break;
            case 'font':
              slidesData.font_suggestions_count_resolved =
                prevSlideInfo.font_suggestions_count !== 0
                  ? slidesData.font_suggestions_count === 0
                  : false;
            // eslint-disable-next-line no-fallthrough
            case 'images':
              slidesData.image_suggestions_count_resolved =
                prevSlideInfo.image_suggestions_count !== 0
                  ? slidesData.image_suggestions_count === 0
                  : false;
            // eslint-disable-next-line no-fallthrough
            default:
              break;
          }
        }

        for (let i = 0; i < Object.keys(slidesData.slide_images).length; i++) {
          const category =
            slidesData.slide_images[i].suggestions.data.slide_data
              .format_properties.format_suggestions[categorySelected] || [];
          const totalCount =
            (prevSlideInfo.slide_images[i].acceptedRejectedCount || 0) +
            (getSuggestionsTotalCount(category) || 0);
          slidesData.slide_images[i].acceptedRejectedCount = totalCount;
        }

        this.setComplyDetails(slidesData);

        const prezentationDataFinal = this.getPreparedPrezentationData(
          slidesData,
          'category',
        );

        this.updatePrezentation(prezentationDataFinal, async () => {
          const payload = {
            id: prezentationDataFinal.id,
            slideToolTips:
              prezentationDataFinal.bestPractices?.slideToolTips || [],
          };

          updateBestPractices(payload)
            .then((res) => {
              console.log(res);
            })
            .catch((error) => console.log(error));
          const mergedUrl = await this.getMergedUrlForDownload();
          downloadConvertTemplateDeck(this.uploadId, mergedUrl);
          this.setLoadingAnimations({
            isLoading: true,
            animationsData: {
              showBlast: true,
              type: 'acceptSuggestionLoader',
            },
          });
          setTimeout(() => {
            this.setLoadingAnimations({
              isLoading: false,
              animationsData: {},
            });
          }, 3000);
        });
      } catch (error) {
        console.log(error);
        this.convertFail = true;
        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      }
    },
    getPreparedPrezentationData(slidesData, requestType) {
      const prezentationDataFinal = JSON.parse(
        JSON.stringify(this.prezentationData),
      );

      let slideToolTips =
        prezentationDataFinal.bestPractices?.slideToolTips || [];
      let index = 0;
      prezentationDataFinal.sections.list.forEach((section, sectionIndex) => {
        section.slides.forEach((slide, slideIndex) => {
          const slideData = slidesData.slide_images[index];
          const otherData = {
            template:
              this.selectedTemplate?.template_internal_name || slide.template,
          };
          if (requestType === 'category') {
            otherData.acceptedRejectedCount = slideData.acceptedRejectedCount;
          } else {
            const acceptedRejectedCountList = getTotalSuggestionsInSlide(
              slideData,
            )
              ?.map((category) => category.suggestions)
              .flat()
              .filter((i) => i.x && i.y);
            otherData.acceptedRejectedCount = acceptedRejectedCountList.length;
          }

          slideToolTips = slideToolTips?.map((s) => {
            const assetId = this.getFilePathWithoutPrependSlash(slide.assetId);
            if (this.getFilePathWithoutPrependSlash(s.assetId) === assetId) {
              return {
                ...s,
                assetId: slideData.png.s3_path,
              };
            }
            return s;
          });
          prezentationDataFinal.sections.list[sectionIndex].slides[slideIndex] =
            {
              ...prezentationDataFinal.sections.list[sectionIndex].slides[
                slideIndex
              ],
              assetId: slideData.png.s3_path,
              filename: slideData.pptx.s3_path,
              ...getSlideType('isComply'),
              ...otherData,
            };
          index++;
        });
      });
      prezentationDataFinal.bestPractices = { slideToolTips };
      prezentationDataFinal.action = {
        operation: 'Comply',
        level: 'Deck',
      };
      prezentationDataFinal.s3dirpath =
        this.prezentationData?.s3dirpath ||
        `prezentation/${this.prezentationData?.id}/`;
      prezentationDataFinal.convertedTo = 'Comply';
      prezentationDataFinal.theme =
        this.selectedTemplate?.template_internal_name;

      return prezentationDataFinal;
    },
    handleFullDeckSuggestionsChange(isFromSuggestions = false) {
      if (this.isPrezentSourceBestPractice) {
        this.fullDeckSuggestionsChange(isFromSuggestions);
      } else {
        this.showConfirmation = true;
      }
      this.suggestionFunctionParameters = { isFromSuggestions };
    },
    async fullDeckSuggestionsChange(isFromSuggestions = false) {
      let countOfSlides = 0;
      let startEventTime = null;
      this.prezentationData.sections.list.forEach((section) => {
        countOfSlides += section.slides.length;
      });
      startEventTime = new Date();
      this.convertFail = false;
      this.signal = new AbortController();

      this.setLoadingAnimations({
        isLoading: true,
        animationsData: {
          showBlast: false,
          type: 'acceptSuggestionLoader',
        },
      });

      const deckData = {
        reference_template: this.selectedTemplate?.template_internal_name,
        upload_id: this.uploadId,
        category: ['color', 'font', 'images', 'template'],
        // eslint-disable-next-line no-nested-ternary
        source: isFromSuggestions
          ? Object.keys(this.complyDetails?.slide_images).length > 0
            ? 'template-conversion'
            : ''
          : '',
        include_image_conversions: isFromSuggestions
          ? true
          : this.imageConversion,
        s3Dir: this.getS3dir(),
      };
      try {
        const prevSlideInfo = this.complyDetails;
        const response = await deckFullCompliance(deckData);
        console.log('response', response);
        this.updateActionCompletedInfo({
          isTcCompleted: true,
        });
        const slidesData = await fetchSlidesData(
          this.uploadId,
          this.signal.signal,
        );
        if (isFromSuggestions) {
          const otherData = {
            [TD_AUD]: this.prezentationData?.audienceID,
            [TD_PREZNAME]: this.prezentationData.name,
            [TD_PREZTYPE]: this.prezentationData?.type,
            [TD_TEMPLATE]: this.prezentationData?.theme,
            [TD_NUMBEROFSLIDES]: countOfSlides,
            [TD_PREZENTATION_ORIGIN]:
              this.prezentationData?.prezentationSource ||
              this.prezentationData?.prezentationType,
            [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
            [TD_TIME_SPENT_IN_SECS]: (new Date() - startEventTime) / 1000,
            [TD_CROSS_FEATURE]:
              this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
          };
          trackPrezentationEvents.convertTemplateAcceptAllSuggestions(
            this.currentUser,
            otherData,
          );
        } else {
          const otherData = {
            [TD_AUD]: this.prezentationData?.audienceID,
            [TD_PREZNAME]: this.prezentationData?.name,
            [TD_PREZTYPE]: this.prezentationData?.type,
            [TD_TEMPLATE]: this.prezentationData?.theme,
            [TD_NUMBEROFSLIDES]: countOfSlides,
            [TD_TIME_SPENT_IN_SECS]: (new Date() - startEventTime) / 1000,
            [TD_PREZENTATION_ORIGIN]:
              this.prezentationData?.prezentationSource ||
              this.prezentationData?.prezentationType,
            [TD_PREZENTATION_COMPANY_SOURCE]: this.prezentationData?.source,
            [TD_CROSS_FEATURE]:
              this.origin === 'build' ? BUILD : TD_PREZENTATION_LIBRARY,
            [TD_COMMON_COLUMN_NAME]:
              TD_MAKE_100_PERCENT_COMPLIANCE_ACCEPT_ALL_SUGGESTIONS,
            [TD_COMMON_COLUMN_VALUE]: TD_MAKE_100_PERCENT_COMPLIACE,
          };
          trackComplyEvents.complianceCheckerMakeHundredCompliant(
            this.currentUser,
            otherData,
          );
        }
        if (slidesData?.status === 'error') {
          this.convertFail = true;
          this.setLoadingAnimations({
            isLoading: false,
            animationsData: {},
          });
          return;
        }
        postFeatureUsage({ feature: 'comply' })
          .then((resp) => {
            if (resp?.data) {
              this.setLimitData(resp.data);
            }
          })
          .catch((error) => console.log(error));
        // comparing with previous state to determine the user action to resolve all the suggested changes https://prezentium.atlassian.net/browse/PM-6729
        if (slidesData && Object.keys(slidesData).length > 0) {
          slidesData.color_suggestions_count_resolved =
            prevSlideInfo.color_suggestions_count !== 0
              ? slidesData.color_suggestions_count === 0
              : false;

          slidesData.font_suggestions_count_resolved =
            prevSlideInfo.font_suggestions_count !== 0
              ? slidesData.font_suggestions_count === 0
              : false;
          slidesData.image_suggestions_count_resolved =
            prevSlideInfo.image_suggestions_count !== 0
              ? slidesData.image_suggestions_count === 0
              : false;
        }
        this.setComplyDetails(slidesData);

        const prezentationDataFinal = this.getPreparedPrezentationData(
          slidesData,
          'full',
        );

        this.updatePrezentation(prezentationDataFinal, async () => {
          const payload = {
            id: prezentationDataFinal.id,
            slideToolTips:
              prezentationDataFinal.bestPractices?.slideToolTips || [],
          };

          updateBestPractices(payload)
            .then((res) => {
              console.log(res);
            })
            .catch((error) => console.log(error));
          this.getMergedUrlForDownload()
            .then((resp) => {
              console.log(resp);

              downloadConvertTemplateDeck(this.uploadId, resp);
            })
            .catch((error) => console.log(error));
          this.setLoadingAnimations({
            isLoading: true,
            animationsData: {
              showBlast: true,
              type: 'acceptSuggestionLoader',
            },
          });
          setTimeout(() => {
            this.setLoadingAnimations({
              isLoading: false,
              animationsData: {},
            });
          }, 3000);
        });
      } catch (error) {
        console.log(error);

        this.convertFail = true;
        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      }
    },
    async handleReviewDetails(imageConversion = false) {
      this.convertFail = false;
      this.signal = new AbortController();
      this.imageConversion = imageConversion;

      this.setLoadingAnimations({
        isLoading: true,
        animationsData: {
          showBlast: false,
          type: 'reviewCheckLoader',
        },
      });
      const payload = {
        slide_images: this.complyDetails?.slide_images || this.getSlideImages(),
        reference_template: this.selectedTemplate?.template_internal_name,
        upload_id: this.uploadId,
        include_image_conversions: imageConversion,
        s3Dir: this.getS3dir(),
      };
      try {
        const slidesData = await reviewDetails(payload, this.signal.signal);
        this.setComplyDetails({ ...slidesData, step: 2 });
        if (slidesData?.status === 'error') {
          this.convertFail = true;
          this.setLoadingAnimations({
            isLoading: false,
            animationsData: {},
          });
          return;
        }
        const prezentationDataFinal = this.getPreparedPrezentationData(
          slidesData,
          'full',
        );

        this.updatePrezentation(prezentationDataFinal, () => {
          const payloadNew = {
            id: prezentationDataFinal.id,
            slideToolTips:
              prezentationDataFinal.bestPractices?.slideToolTips || [],
          };

          updateBestPractices(payloadNew)
            .then((res) => {
              console.log(res);
            })
            .catch((error) => console.log(error));

          this.rightPanelComponent = 'complySlideDetails';
          this.isLoading = false;
          this.setLoadingAnimations({
            isLoading: true,
            animationsData: {
              showBlast: true,
              type: 'reviewCheckLoader',
            },
          });
          setTimeout(() => {
            this.setLoadingAnimations({
              isLoading: false,
              animationsData: {},
            });
          }, 3000);
        });
        if (this.isPrezentSourceBestPractice) {
          this.rightPanelComponent = 'complySlideDetails';
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.convertFail = true;
        this.isLoading = false;
        this.setLoadingAnimations({
          isLoading: false,
          animationsData: {},
        });
      }
    },
    handleUpdateIncludeImageConversion(imageConversion = false) {
      this.imageConversion = imageConversion;
    },

    getSlideImages() {
      const slideImages = {};
      let slideCount = 0;
      this.prezentationData.sections.list?.forEach((section) => {
        section.slides.forEach((slide) => {
          slideImages[slideCount] = {
            png: {
              s3_path: this.getFilePathWithoutPrependSlash(slide.assetId),
              s3_bucket: getS3Bucket(slide.filename, slide.isOPUploaded),
            },
            pptx: {
              s3_path: this.getFilePathWithoutPrependSlash(slide.filename),
              s3_bucket: getS3Bucket(slide.filename, slide.isOPUploaded),
            },
          };
          slideCount++;
        });
      });

      return slideImages;
    },
    getFilePathWithoutPrependSlash(filename) {
      return filename
        ?.split('/')
        .filter((t) => t)
        .join('/');
    },
    async getMergedUrlForDownload() {
      const s = {
        outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        outputPath: `private/template-converter/comply/${this.uploadId}/mergeslides/`,
        slides: [],
      };
      this.prezentationData.sections.list.forEach((section) => {
        section.slides.forEach((slide) => {
          s.slides.push({
            slideId:
              slide?.filename?.charAt(0) === '/'
                ? slide?.filename?.substring(1)
                : slide?.filename,
            isReplaced: false,
            isUploaded: false,
            isGenerated: false,
            isSynthesis: false,
            isRedesign: false,
            isComply: true,
          });
        });
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `private/template-converter/comply/${this.uploadId}/mergeslides/${mergedUrl}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.tc-suggestions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;

  .tc-body {
    height: inherit;
  }

  .heading-block {
    &__main {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__sub {
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .suggest-block {
    align-self: stretch;
    &__summary-text {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      margin-bottom: 0;
    }
  }
  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .tab-item {
    color: #4d5358;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 130%;
    text-transform: none;
  }

  ::v-deep .suggest-block-score-section {
    margin: 20px 0 !important;
  }

  .error-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-content: center;
    justify-content: center;
    .error-text {
      margin: 20px auto;
      font-size: 14px;
    }
    .try-again-btn {
      text-align: center;

      .v-btn {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
      }
    }
  }
}
</style>
