<template>
  <div class="right-panel-context">
    <div class="manage-request-detail">
      <template v-if="viewAddMessage">
        <div class="add-message-container">
          <div class="back-wrap">
            <v-btn @click="backToManageRequest">
              <v-icon>mdi-chevron-left</v-icon>
              {{ $t('common.back') }}
            </v-btn>
          </div>
          <div class="add-message-title">Add message</div>
          <AddRequestV2 @onDataSubmit="manageSubmitData" :updating="updating" />
        </div>
      </template>
      <template v-else>
        <div class="heading">Manage request</div>
        <template v-if="loading">
          <div class="loader-container">
            <ClipLoader color="#21a7e0" />
          </div>
        </template>
        <template v-else>
          <div class="comment-wrap" ref="messageContainer">
            <div
              class="comment-container"
              v-for="(comment, index) in comments"
              :key="index + 'comment'"
            >
              <div class="comment-details">
                <div
                  class="item-logo"
                  v-if="comment.updatedBy === 'ZendeskComment'"
                >
                  <img src="/assets/img/overnight-prezentations/new-logo.svg" />
                </div>
                <div class="item-logo" v-else>
                  <!-- {{ item }} -->
                  <img
                    :src="getImage(comment.profileImage)"
                    alt=""
                    v-if="comment.profileImage"
                    class="profile-imgg"
                  />
                  <v-avatar
                    color="rgb(88, 168, 222)"
                    size="25"
                    class="profile-imgg"
                    v-else
                  >
                    <span
                      class="profile-avatar--initial"
                      v-html="userInitials(comment.firstName, comment.lastName)"
                      size="20"
                    ></span>
                  </v-avatar>
                </div>
                <div class="author-name">
                  {{
                    comment.updatedBy === 'ZendeskComment'
                      ? $t('common.prezent')
                      : getUpdatedBy(comment)
                  }}
                </div>
              </div>
              <div
                class="date-wrap"
                v-html="getDateFunction(comment.createdAt)"
              ></div>
              <div class="message-wrap">
                <div
                  v-html="comment.htmlBody"
                  v-if="comment.htmlBody"
                  class="message"
                ></div>
                <div
                  v-else
                  v-for="(ele, i) in comment.comment"
                  :key="i"
                  class="message"
                >
                  {{ ele }}
                </div>
              </div>
              <div class="attachment-wrap" v-if="comment.attachments.length">
                <div
                  v-for="file in comment.attachments"
                  :key="file.attachmentId"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="conversation-attachments"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleFileNameClick(file)"
                      >
                        {{ file.fileName ? file.fileName : file.linkUrl }}
                      </div>
                    </template>
                    <span>
                      {{ file.fileName ? file.fileName : file.linkUrl }}</span
                    >
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>

          <div class="add-message-wrap" v-if="showAddMessage">
            <v-btn
              outlined
              rounded
              color="primary"
              class="cancel-btn"
              @click="handleAddMessage"
            >
              <v-icon> mdi-plus </v-icon>
              Add message
            </v-btn>
            <div class="time-limit-message">
              We recommend you to submit your reply before 5:30 PM PST
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapActions, mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';
import { downloadFileWithCustomName } from '../../../../../../utils/common';
import {
  getOPConversationHistory,
  getOPTicketsMessage,
  updateOpRequestWithAttachmentAndLinks,
  // deleteOpRequestAttachmentsAndLinks,
  logOPDownload,
} from '../../../../../../utils/api-helper';
import { getInitials } from '@/utils/common';
import AddRequestV2 from './AddRequestV2.vue';

export default {
  name: 'ManageRequests',
  data() {
    return {
      loading: true,
      updating: false,
      // showAddMessage: false,
      viewAddMessage: false,
      headers: [
        {
          text: 'Commenter',
          value: 'updatedBy',
        },
        {
          text: 'Message',
          value: 'comment',
        },
        {
          text: 'Files/Links',
          value: 'attachments',
          width: '400px',
        },
        {
          text: 'Timestamp',
          value: 'createdAt',
          width: '140px',
        },
      ],
      comments: [],
      processedProfileImage: '',
      instructions: [],
    };
  },
  components: { ClipLoader, AddRequestV2 },
  methods: {
    ...mapActions('users', ['setOPTicketUnreadMessageData']),
    async manageSubmitData(data) {
      if (
        this.currentPrezentation.status !== 'Completed' ||
        !this.completedMoreThanSevenDays
      ) {
        this.updating = true;
        let links = [];
        if (data.links) {
          links = data?.links.map((ele) => ({
            linkUrl: ele.web_url,
            isShared: true,
          }));
        }
        this.instructions = data?.texts?.text || '';
        if (
          this.instructions.length > 0 ||
          data.files.length > 0 ||
          links.length > 0
        ) {
          const payload = {
            ticketId: this.currentPrezentation.ticketId,
            attachment: {
              files: data.files,
              links,
            },
            instructions:
              this.instructions && this.instructions.length > 0
                ? [this.instructions.replace(/\n/g, '<br />')]
                : [],
          };
          await updateOpRequestWithAttachmentAndLinks(payload)
            .then((resp) => {
              console.log(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        this.updating = false;
        this.getOPConversationHistoryData();
        this.viewAddMessage = false;
      }
    },
    scrollToBottom() {
      const { messageContainer } = this.$refs;
      if (messageContainer) {
        messageContainer.scrollTop = messageContainer.scrollHeight;
        window.scrollTo({
          top: messageContainer.scrollTop,
          behaviour: 'smooth',
        });
      }
    },
    getImage(url) {
      getFileURL(null, `public/${url}`, null, 'storage').then((res) => {
        this.processedProfileImage = res;
      });
      console.log(`Final image : ${this.processedProfileImage}`);
      return this.processedProfileImage;
    },
    backToManageRequest() {
      this.viewAddMessage = false;
      setTimeout(() => {
        this.scrollToBottom();
      }, 200);
    },
    getUpdatedBy(item) {
      if (item?.updatedBy === this.currentUser?.user?.id) return 'Me';
      return `${item.firstName} ${item.lastName}`;
    },
    sortedComments(comments) {
      return comments.sort((a, b) => {
        if (a.createdAt < b.createdAt) return -1;
        if (a.createdAt > b.createdAt) return 1;
        return 0;
      });
    },
    async getOPConversationHistoryData() {
      try {
        this.loading = true;
        await getOPConversationHistory(this.id).then((res) => {
          this.comments = this.sortedComments([...res.data.comments]);
          this.comments.forEach((comment, index) => {
            // if the commen had line breaks, change it back for UI render
            if (comment?.comment && comment?.comment[0])
              comment.comment[0] = comment.comment[0].replace(/<br \/>/g, '\n');
            comment.sl_no = index;
          });
        });
        this.loading = false;
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);
      } catch (error) {
        console.log(error);
      }
    },
    itemRowBackground(item) {
      const messageCount = this.getUnReadMessageCount || this.getMessageCount;
      return messageCount > item?.sl_no ? 'unread-msg' : '';
    },
    handleAddMessage() {
      this.viewAddMessage = true;
    },
    userInitials(firstName, lastName) {
      console.log(getInitials(firstName, lastName));
      return getInitials(firstName, lastName);
    },
    handleFileNameClick(file) {
      if (file?.fileName) {
        downloadFileWithCustomName(
          file?.attachmentUrl,
          file?.fileName,
          () => {},
          // this.downloadInProgress,
          false,
        );
        logOPDownload(this.id)
          .then((resp) => {
            console.log('logOPDownload', resp);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (file?.linkUrl) window.open(file?.linkUrl, '_blank');
    },
    getDateFunction(timestamp) {
      const date = new Date(`${timestamp}Z`);
      const pstFormatter = new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/Los_Angeles',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });
      const pstDateString = pstFormatter.format(date);
      return `${pstDateString} PST`;
    },
    formatAMPM(date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours %= 12;
      hours = hours % 12 ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      const strTime = `${hours}:${minutes} ${ampm}`;
      return strTime;
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'opUnreadMessageData']),
    showAddMessage() {
      return (
        this.currentPrezentation.status !== 'Completed' ||
        !this.completedMoreThanSevenDays
      );
    },
    getMessageCount() {
      const getTicket = this.opUnreadMessageData?.find(
        (item) => item?.ticketId === this.id,
      );
      if (getTicket) return getTicket?.unreadCount;
      return 0;
    },
    completedMoreThanSevenDays() {
      if (!this.currentPrezentation?.completionDate) return false;
      const completionDateString = this.currentPrezentation?.completionDate;
      const completionDate = new Date(completionDateString);
      const currentDate = new Date();
      const timeDifference = currentDate - completionDate;
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      return daysDifference > 7;
    },
  },
  async mounted() {
    this.loading = true;
    const result = await getOPTicketsMessage();
    this.setOPTicketUnreadMessageData(result?.data);
    getOPConversationHistory(this.id).then((res) => {
      this.loading = false;
      this.comments = this.sortedComments([...res.data.comments]);
      this.comments.forEach((comment, index) => {
        // if the commen had line breaks, change it back for UI render
        if (comment?.comment && comment?.comment[0])
          comment.comment[0] = comment.comment[0].replace(/<br \/>/g, '\n');
        comment.sl_no = index;
      });
      setTimeout(() => {
        this.scrollToBottom();
      }, 200);
    });
    // this.showAddMessage = this.currentPrezentation.status !== 'Completed';
  },
  watch: {
    isManagedClosed(val) {
      if (val === true) {
        this.getOPConversationHistoryData();
      }
    },
    getUnReadMessageCount() {
      this.getOPConversationHistoryData();
    },
  },
  props: {
    id: {
      type: String,
    },
    isManagedClosed: {
      type: Boolean,
    },
    getUnReadMessageCount: {
      type: Number,
      default: 0,
    },
    currentPrezentation: {
      typeof: Object,
      default: {},
    },
  },
  async beforeDestroy() {
    this.$emit('onClose', 'closeManageRequest');
  },
};
</script>

<style lang="scss" scoped>
.right-panel-context {
  height: 100%;
  .manage-request-detail {
    height: 100%;
    .heading {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-top: 4px;
    }
    .comment-wrap {
      width: 100%;
      height: calc(100% - 129px);
      overflow-y: auto;
      overflow-x: hidden;
      .comment-container {
        padding-bottom: 10px;
        .comment-details {
          display: flex;
          align-items: center;
          min-width: 200px;
          padding-bottom: 2px;
          .item-logo {
            height: 25px;
            margin-right: 5px;
            .profile-imgg {
              color: white;
              height: 25px;
              width: 25px;
              border-radius: 50%;
              overflow: hidden;
            }
          }
          .author-name {
            font-family: Lato;
            font-size: 16px;
            font-weight: 600;
            line-height: 19.2px;
            text-align: left;
          }
        }
        .date-wrap {
          font-family: Lato;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.8px;
          letter-spacing: 0.01em;
          text-align: left;
          color: #697077;
          padding-bottom: 10px;
        }
        .message-wrap {
          padding-bottom: 10px;
        }
        .attachment-wrap {
          padding-bottom: 10px;
          .conversation-attachments {
            color: #21a7e0;
            cursor: pointer;
            display: block;
            text-align: left;
            margin-bottom: 5px;
          }
        }
      }
    }

    .add-message-wrap {
      position: fixed;
      width: 25%;
      padding-top: 10px;
      background-color: white;
      .v-btn {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: normal;
        text-transform: none !important;
        margin-right: 20px;
        background-color: white;
      }
      .time-limit-message {
        font-family: Lato;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #343a3f;
        margin-top: 10px;
      }
    }
    .add-message-container {
      height: 100%;
      .back-wrap {
        .v-btn {
          border: none;
          text-transform: none;
          background-color: #ffffff;
          box-shadow: none;
          letter-spacing: normal;
          color: #21a7e0;
          padding: 0px 0px 0px 0px;
          margin-left: -11px;
        }
      }

      .add-message-title {
        font-family: Lato;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }
    }
    .loader-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}
</style>
