<template>
  <div class="wrapper">
    <div>
      <v-btn
        class="close-btn"
        icon
        @click="handleClose"
        v-if="trigger != 'header'"
      >
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <v-btn v-else class="close-btn" icon @click="close">
        <v-icon dark v-text="`mdi-close`" />
      </v-btn>
      <div :class="`content-wrapper ${trigger != 'header' ? '' : 'popper'}`">
        <div class="add-audience__title">
          {{ $t('fingerprint.searchPage.addNewAudience') }}
        </div>
        <div class="add-heart__title">
          <v-icon small :color="'blue'">{{ heartIcon }}</v-icon>
          <div class="add-heart__title_subtext">
            {{ $t('fingerprint.weDonateForEachFingerprint') }}
          </div>
        </div>
        <span class="learn_more" @click="openShareMenu">{{
          $t('fingerprint.learnMore')
        }}</span>
        <div class="first-screen" v-if="step === 1">
          <v-img
            class="send-request__img"
            contain
            :src="'/assets/img/audience/add_new_audience_donate.svg'"
            alt
          ></v-img>
          <div class="audience-type__title">
            {{ $t('navbarActions.addColleagueDetail.audienceType') }}
            <div>
              <template>
                <v-container class="px-0 py-0" fluid>
                  <v-radio-group
                    row
                    v-model="audienceType"
                    data-pendo-id="addAudienceTypes"
                  >
                    <v-radio
                      color="#20a7e0"
                      :label="$t('navbarActions.addColleagueDetail.individual')"
                      value="individual"
                    />
                    <v-radio
                      color="#20a7e0"
                      :label="$t('prezentationShareStatus.group')"
                      value="group"
                    />
                  </v-radio-group>
                </v-container>
              </template>
            </div>
          </div>
          <div v-if="audienceType === 'group'">
            <div class="group-content-panel">
              <div>
                <div class="content-label">
                  {{ $t('fingerprint.groupInsights.groupName') }}
                </div>
                <v-text-field
                  :placeholder="$t('fingerprint.groupInsights.groupName')"
                  v-model="firstName"
                  :rules="[...emojiRules, ...groupNameRequired]"
                />
              </div>
            </div>
            <div class="group-content-panel pt-0 mt-0">
              <div>
                <div class="content-label">
                  {{ $t('navbarActions.addColleagueDetail.groupEmail') }}
                </div>
                <v-text-field
                  :placeholder="$t('navbarActions.shareFreeTrial.workEmail')"
                  v-model="email"
                  type="email"
                  id="group-email"
                  @blur="handleEmailChange"
                  :rules="groupEmailRules"
                />
              </div>
              <div>
                <div class="content-label">
                  {{ $t('navbarActions.addColleagueDetail.companyName') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('navbarActions.addColleagueDetail.audienceCompany')
                  "
                  v-model="audienceCompanyName"
                  :rules="[...emojiRules, ...companyNameRequired]"
                  :disabled="isAudienceCompanyNameDisabled"
                  :loading="isCompanyNameLoading"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="6">
                <div class="content-label">
                  {{ $t('navbarActions.addColleagueDetail.firstName') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('navbarActions.addColleagueDetail.audienceFirstName')
                  "
                  v-model="firstName"
                  :rules="[...emojiRules, ...firstNameRequired]"
                  data-pendo-id="addAudienceFirstName"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <div class="content-label">
                  {{ $t('navbarActions.addColleagueDetail.lastName') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('navbarActions.addColleagueDetail.audienceLastName')
                  "
                  v-model="lastName"
                  :rules="[...emojiRules, ...lastNameRequired]"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="6 pt-0">
                <div class="content-label">
                  {{ $t('navbarActions.addColleagueDetail.email') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('navbarActions.addColleagueDetail.audienceWorkEmail')
                  "
                  v-model="email"
                  type="email"
                  :rules="individualEmailRules"
                  id="audience-email"
                  @blur="handleEmailChange"
                />
              </v-col>
              <v-col cols="6 pt-0">
                <div class="content-label">
                  {{ $t('navbarActions.addColleagueDetail.companyName') }}
                </div>
                <v-text-field
                  :placeholder="
                    $t('navbarActions.addColleagueDetail.audienceCompany')
                  "
                  v-model="audienceCompanyName"
                  :rules="[...emojiRules, ...companyNameRequired]"
                  :disabled="isAudienceCompanyNameDisabled"
                  :loading="isCompanyNameLoading"
              /></v-col>
            </v-row>
          </div>
          <v-container
            class="px-0 py-0 pl-2"
            fluid
            data-pendo-id="addAudienceRequestTypes"
          >
            <v-radio-group class="pt-0" v-model="addAudienceBy">
              <v-radio
                :disabled="isRequestFingerprintDisabled"
                value="send"
                color="#20a7e0"
              >
                <span
                  slot="label"
                  :class="`black--text ${
                    isRequestFingerprintDisabled
                      ? 'option-description__disabled'
                      : 'option-description'
                  }`"
                  >{{ $t('navbarActions.addColleagueDetail.request') }}
                  <v-tooltip
                    top
                    max-width="220"
                    content-class="tooltip-content"
                    fixed
                    open-delay="500"
                    close-delay="300"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="icon-title info-icon"
                        v-bind="attrs"
                        v-on="on"
                        v-text="`mdi-information-outline`"
                      />
                    </template>
                    <span>
                      {{
                        $t(
                          'navbarActions.addColleagueDetail.youWillGetTheChoiceToEmail',
                        )
                      }}
                    </span>
                  </v-tooltip></span
                >
              </v-radio>
              <v-radio value="complete" color="#20a7e0">
                <div slot="label">
                  <span class="black--text option-description"
                    >{{
                      $t(
                        'navbarActions.addColleagueDetail.completeaFingerprintAssessment',
                      )
                    }}
                    <v-tooltip
                      top
                      max-width="260"
                      content-class="tooltip-content"
                      fixed
                      open-delay="500"
                      close-delay="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="icon-title info-icon"
                          v-bind="attrs"
                          v-on="on"
                          v-text="`mdi-information-outline`"
                        />
                      </template>
                      <span
                        >{{
                          $t(
                            'navbarActions.addColleagueDetail.createTheFingerprint',
                          )
                        }}
                      </span>
                    </v-tooltip></span
                  >
                </div>
              </v-radio>
              <v-radio value="zensense" color="#20a7e0">
                <div slot="label">
                  <span class="black--text option-description">
                    <NewFeatureBadge
                      module-name="addaudienceform"
                      :feature-id="'addaudienceformdropdown_id'"
                      :offsetX="'-18'"
                      :offsetY="'22'"
                      :size="'medium'"
                    >
                      {{
                        $t(
                          'navbarActions.addColleagueDetail.generateFingerprintUsingZensense',
                        )
                      }}
                    </NewFeatureBadge>
                    <v-tooltip
                      bottom
                      max-width="280"
                      content-class="tooltip-content"
                      fixed
                      open-delay="500"
                      close-delay="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="icon-title info-icon"
                          v-bind="attrs"
                          v-on="on"
                          v-text="`mdi-information-outline`"
                        />
                      </template>
                      <span
                        >{{
                          $t(
                            'navbarActions.addColleagueDetail.answeraFewSimpleQuestions',
                          )
                        }}
                      </span>
                    </v-tooltip>
                  </span>
                </div>
              </v-radio>
            </v-radio-group>
          </v-container>
          <div class="action-wrapper">
            <v-btn
              v-if="
                addAudienceBy === 'complete' || addAudienceBy === 'zensense'
              "
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              :disabled="isInvalid"
              @click="handleCompleteAssessment"
              :loading="getLoading"
              data-pendo-id="addAudienceContinue"
            >
              {{ $t(buttenText) }}
            </v-btn>
            <v-btn
              v-else
              class="primary-button"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              :disabled="isInvalid"
              @click="handleRequestFingerprint"
              :loading="getLoading"
              data-pendo-id="addAudienceContinue"
            >
              {{ $t('common.continue') }}
            </v-btn>
          </div>
        </div>
        <div class="second-step" v-if="step === 2 && audienceType !== 'group'">
          <div class="send-assessment">
            <v-img
              v-if="isSent"
              class="send-request__img"
              :src="'/assets/img/audience/email-sent-success.svg'"
              max-height="200"
              alt
            ></v-img>
            <v-img
              v-else
              class="send-request__img"
              :src="'/assets/img/audience/send-request-image.svg'"
              max-height="200"
              alt
            ></v-img>
            <v-row class="mt-10 pl-3">
              <v-col class="send-options mr-4"
                ><div class="send-title">
                  {{
                    $t(
                      'navbarActions.addColleagueDetail.sendFingerprintAssessment',
                    )
                  }}
                </div>
                <div @click="openEmailModal" class="preview-email">
                  {{ $t('navbarActions.addColleagueDetail.preview') }}
                </div>
                <v-btn
                  class="primary-button send-options__button"
                  rounded
                  color="#21a7e0"
                  @click="handleSendAssessment"
                  data-pendo-id="addAudienceSendEmail"
                >
                  <v-icon> mdi-email-arrow-right-outline</v-icon>
                  <span
                    >{{
                      isSent
                        ? $t('navbarActions.addColleagueDetail.emailSent')
                        : $t('navbarActions.shareFreeTrial.sendEmail')
                    }}
                  </span>
                </v-btn></v-col
              >
              <v-col class="send-options ml-4">
                <div class="copy-title">
                  {{
                    $t(
                      'navbarActions.addColleagueDetail.shareaFingerprintAssessmentLink',
                    )
                  }}
                </div>
                <v-btn
                  class="primary-button send-options__button"
                  rounded
                  color="#21a7e0"
                  @click="handleCopyLink"
                >
                  <v-icon> mdi-content-copy</v-icon>
                  <span>{{
                    isCopied
                      ? $t('navbarActions.shareFreeTrial.copied')
                      : $t('navbarActions.shareFreeTrial.copyLink')
                  }}</span>
                </v-btn>
                <div class="offscreen">
                  <v-container>
                    <v-text-field
                      aria-hidden="true"
                      v-model="fpTestLink"
                      ref="textToCopy"
                    />
                  </v-container></div
              ></v-col>
            </v-row>
          </div>
        </div>
        <div class="second-screen" v-if="step == 3 && audienceType !== 'group'">
          <div class="send-assessment">
            <v-img
              class="send-request__img"
              :src="'/assets/img/audience/send-request-image.svg'"
              max-height="200"
              alt
            ></v-img>
            <v-row class="mt-10 pl-3">
              <v-col class="send-options mr-4"
                ><div class="send-title">
                  {{
                    $t(
                      'navbarActions.addColleagueDetail.sendFingerprintAssessment',
                    )
                  }}
                </div>
                <v-btn
                  class="primary-button send-options__button"
                  rounded
                  color="#21a7e0"
                  @click="handleSendAssessment"
                  data-pendo-id="addAudienceSendEmail"
                >
                  <v-icon> mdi-email-arrow-right-outline</v-icon>
                  <span
                    >{{
                      isSent
                        ? $t('navbarActions.addColleagueDetail.emailSent')
                        : $t('navbarActions.shareFreeTrial.sendEmail')
                    }}
                  </span>
                </v-btn></v-col
              >
              <v-col class="send-options ml-4">
                <div class="copy-title">
                  {{
                    $t(
                      'navbarActions.addColleagueDetail.shareaFingerprintAssessmentLink',
                    )
                  }}
                </div>
                <v-btn
                  class="primary-button send-options__button"
                  rounded
                  color="#21a7e0"
                  @click="handleCopyLink"
                >
                  <v-icon> mdi-content-copy</v-icon>
                  <span>{{
                    isCopied
                      ? $t('navbarActions.shareFreeTrial.copied')
                      : $t('navbarActions.addColleagueDetail.copyLink')
                  }}</span>
                </v-btn>
                <div class="offscreen">
                  <v-container>
                    <v-text-field
                      aria-hidden="true"
                      v-model="fpTestLink"
                      ref="textToCopy"
                    />
                  </v-container></div
              ></v-col>
            </v-row>
          </div>
        </div>
        <div v-if="step === 5">
          <v-row class="mt-10 pl-3" justify="center">
            <v-img
              class="send-request__img"
              :src="'/assets/img/audience/account-already-exists.svg'"
              max-height="400"
              max-width="401"
              alt
            ></v-img>
            <div class="existing-user__screen">
              <div class="existing-user__title">
                {{ $t('navbarActions.addColleagueDetail.greatNews') }}
              </div>
              <div class="error-content__description">
                <span>{{
                  $t(
                    'navbarActions.addColleagueDetail.AudienceFingerprintAvailable',
                  )
                }}</span>
                <span>
                  {{
                    $t(
                      'navbarActions.addColleagueDetail.wouldYouLikeToAddAnother',
                    )
                  }}</span
                >
              </div>
              <v-btn
                class="primary-button"
                rounded
                color="#21a7e0"
                min-width="134"
                @click="handleNextClick('request', true)"
              >
                {{ $t('fingerprint.searchPage.addNewAudience') }}
              </v-btn>
            </div>
          </v-row>
        </div>
        <div v-if="step === 6">
          <div class="error-content">
            <div class="img-wrapper">
              <img
                src="/assets/img/collegue-outside-org-illustration.svg"
                width="235px"
                height="238px"
              />
            </div>
            <p>
              {{
                $t('navbarActions.shareFreeTrial.colleagueOutsideOrganisation')
              }}
            </p>
            <p>
              {{ $t('navbarActions.shareFreeTrial.tryColleagueWorkEmail') }}
            </p>
            <div class="action-wrapper align-btn-left">
              <v-btn
                class="next"
                rounded
                color="#20a7e0"
                width="134"
                height="50"
                @click="handleNextClick('request', true)"
              >
                <!-- :disabled="
                          !requestFingerPrint.firstName &&
                          !requestFingerPrint.lastName &&
                          !requestFingerPrint.email
                "-->
                {{ $t('common.tryagain') }}
              </v-btn>
            </div>
          </div>
        </div>
        <WaitListAudienceCompany
          v-if="step === 7"
          :handleTakeAssessment="createIndividualPrivateAudience"
          :handleAddNewAudience="handleNextClick.bind(this, 'request', true)"
          :takeAssessmentButtonText="
            audienceAlreadyCreated
              ? reTakeAssessmentBtnText
              : takeAssessmentBtnText
          "
        />
        <FingerprintAvailable
          v-if="step === 4"
          :audienceData="audienceResponse"
          :handleAddNewAudience="handleNextClick.bind(this, 'request', true)"
          :handleClose="handleClose"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { mdiCardsHeart } from '@mdi/js';
import { getFileURL } from '@/utils/calyrex';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import AudienceAPI from '../../../API/audience-api';
import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import { getDonationDetails, sendEmail } from '../../../utils/api-helper';
import { isEmojiExist, isValidEmail } from '../../utils';
import { capitalizeFirstLetter } from '../../../utils/common';
import { PendoAnalyticsHandler } from '../../common/Analytics/PendoAnalyticsHandler';
import LearnMorePop from '../PrezentWithHeart/LearnMorePop.vue';
import EmailPreviewModal from '../../common/EmailPreviewModal.vue';
import WaitListAudienceCompany from '../AddNewAudience/WaitListAudienceCompany.vue';
import FingerprintAvailable from '../AddNewAudience/FingerprintAvailable.vue';
import { trackGeneralEvents } from '../../common/Analytics/GeneralEvents';
import {
  TD_AUDIENCE_ADD_TYPE_COMPLETE,
  TD_AUDIENCE_ADD_TYPE_REQUEST,
  TD_AUDIENCE_ADD_TYPE_ZENSENSE,
  TD_AUDIENCE_TYPE_GROUP,
  TD_AUDIENCE_TYPE_INDIVIDUAL,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'AddCollegueDetail',
  props: {
    addColleague: {
      type: Function,
      default: () => ({}),
    },
    origin: {
      type: String,
      default: '',
    },
    close: {
      type: Function,
      default: () => ({}),
    },
    trigger: {
      type: String,
      default: null,
    },
  },
  emits: ['subModalOpen'],
  data() {
    return {
      step: 1,
      menuPosition: {
        x: 0,
        y: 0,
      },
      contributors: [],
      totalCount: 0,
      buttenText: 'fingerprint.takeAssessment',
      individualEmailRules: [
        (v) => !!v || this.$t('warnings.emailRequired'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => isValidEmail(v) || this.$t('warnings.emailMustBeValid'),
      ],
      groupEmailRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => isValidEmail(v) || !v || this.$t('warnings.emailMustBeValid'),
      ],
      emojiRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => v.length <= 50 || this.$t('warnings.inputMustBeLessThan'),
      ],
      firstNameRequired: [(v) => !!v || this.$t('warnings.firstNameRequired')],
      lastNameRequired: [(v) => !!v || this.$t('warnings.lastNameRequired')],
      groupNameRequired: [(v) => !!v || this.$t('warnings.groupNameRequired')],
      companyNameRequired: [
        (v) => !!v || this.$t('warnings.companyNameRequired'),
      ],
      isSent: false,
      isCopied: false,
      email: '',
      firstName: '',
      lastName: '',
      showShare: false,
      previewEmail: {},
      audienceCompanyName: '',
      isCompanyNameLoading: false,
      isAudienceCompanyNameDisabled: true,
      heartIcon: mdiCardsHeart,
      isSameDomain: true,
      loading: false,
      fpTestLink: '',
      functionList: [
        'Engineering',
        'Finance',
        'HR',
        'Marketing',
        'Operations',
        'Product',
        'Sales',
        'Services',
        'Training',
        'Management',
        'Management1',
      ],
      titleList: ['Executive', 'Mid-Mgmt', 'Junior-Mgmt'],
      audienceType: 'individual',
      addAudienceBy: 'send',
      audienceResponse: {},
      takeAssessmentBtnText: 'fingerprint.takeAssessment',
      reTakeAssessmentBtnText: 'fingerprint.retakeAssessment',
      audienceAlreadyCreated: false,
      trackingData: {},
    };
  },
  components: {
    WaitListAudienceCompany,
    FingerprintAvailable,
    NewFeatureBadge,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'getCompanyDomain',
      'isEmailOutsideCompanyEnabled',
    ]),
    getLoading() {
      return this.loading;
    },
    isInvalid() {
      let isEmailValid = true;

      if (this.audienceType !== 'group' || this.email) {
        isEmailValid =
          isEmailValid &&
          this.audienceCompanyName &&
          this.individualEmailRules[0](this.email) === true &&
          this.individualEmailRules[1](this.email) === true &&
          this.individualEmailRules[2](this.email) === true &&
          this.emojiRules[0](this.firstName) === true &&
          this.emojiRules[1](this.firstName) === true &&
          this.emojiRules[0](this.lastName) === true &&
          this.emojiRules[1](this.lastName) === true;
      }

      if (this.audienceType === 'group') {
        isEmailValid =
          this.audienceCompanyName &&
          this.groupEmailRules[1](this.email) === true &&
          this.emojiRules[0](this.firstName) === true &&
          this.emojiRules[1](this.firstName) === true;
      }

      const val =
        !this.audienceType ||
        (this.audienceType === 'group' && !this.firstName) ||
        (this.audienceType === 'individual' &&
          (!this.firstName || !this.lastName || !this.addAudienceBy)) ||
        !isEmailValid;

      return val;
    },
    isEmailValid() {
      if (this.audienceType !== 'group' || this.email) {
        return (
          this.individualEmailRules[0](this.email) === true &&
          this.individualEmailRules[1](this.email) === true &&
          this.individualEmailRules[2](this.email) === true
        );
      }
      return false;
    },
    isRequestFingerprintDisabled() {
      if (this.audienceType === 'group') {
        return true;
      }

      if (!this.isEmailOutsideCompanyEnabled && !this.isSameDomain) {
        return true;
      }

      return false;
    },
  },
  mounted() {
    trackGeneralEvents.addnewaudienceClick(this.currentUser, {}, this.origin);
  },
  watch: {
    audienceType(val) {
      if (val === 'group') {
        this.addAudienceBy = 'complete';
        this.isAudienceCompanyNameDisabled = false;
      } else {
        this.addAudienceBy = 'send';
        this.isAudienceCompanyNameDisabled = true;
      }

      // wipe out email field
      this.email = '';
      this.isSameDomain = true;
    },
  },
  methods: {
    isEmailDomainSame(v) {
      if (v) {
        const domain = this.currentUser.user.id.split('@')[1];
        const colleagueDomain = v.split('@')[1];
        return domain === colleagueDomain;
      }
      return true;
    },
    async isAudienceExist(email) {
      const audience = await AudienceAPI.methods.getTypesenseAudience(
        email,
        'private_audience',
      );
      return audience?.document.id && audience?.document.fingerPrint;
    },

    async handleEmailChange() {
      if (this.isEmailValid) {
        const domain = this.currentUser.user.id.split('@')[1];
        const colleagueDomain = this.email.split('@')[1];
        this.isSameDomain = domain === colleagueDomain;

        if (
          (!this.isEmailOutsideCompanyEnabled && !this.isSameDomain) ||
          this.audienceType === 'group'
        ) {
          this.addAudienceBy = 'complete';
        } else {
          this.addAudienceBy = 'send';
        }
      }

      if (this.email) {
        this.email = this.email.toLowerCase();
        this.audienceAlreadyCreated = await this.isAudienceExist(this.email);
      } else {
        this.audienceAlreadyCreated = false;
      }

      if (this.audienceAlreadyCreated) {
        this.buttenText = this.reTakeAssessmentBtnText;
      } else {
        this.buttenText = this.takeAssessmentBtnText;
      }

      if (this.isEmailValid) {
        const companyDomain = this.email.substring(
          this.email.lastIndexOf('@') + 1,
        );
        this.isCompanyNameLoading = true;
        AudienceAPI.methods
          .getCompanyNameByDomain(companyDomain)
          .then(async (data) => {
            if (data && data.companyName) {
              this.audienceCompanyName = data.companyName;
              this.isAudienceCompanyNameDisabled = true;
            } else {
              const externalCompanyData =
                await AudienceAPI.methods.getExternalCompanyNameByDomain(
                  companyDomain,
                );
              if (externalCompanyData && externalCompanyData.companyName) {
                this.audienceCompanyName = externalCompanyData.companyName;
                this.isAudienceCompanyNameDisabled = true;
              } else {
                const cNameArray = companyDomain.split('.');
                const cName =
                  cNameArray && cNameArray.length ? cNameArray[0] : '';
                this.enableCompanyName(capitalizeFirstLetter(cName));
              }
            }
            this.isCompanyNameLoading = false;
          })
          .catch((e) => {
            console.log('error', e);
            this.isCompanyNameLoading = false;
          });
      } else {
        this.enableCompanyName();
      }
      if (!this.email && this.audienceType === 'group') {
        this.enableCompanyName();
      }
    },
    enableCompanyName(cName) {
      this.audienceCompanyName = cName || '';
      this.isAudienceCompanyNameDisabled = false;
    },
    subModalClosed() {
      this.$emit('subModalOpen', true);
    },
    openEmailModal() {
      const requester = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;
      if (window.location.origin.includes('localhost')) {
        this.fpTestLink = `${window.location.origin}/fingerprintsignin?inviteId=${this.uuid}`;
      } else {
        const appDomain = process.env.VUE_APP_DOMAIN;
        this.fpTestLink = `${appDomain}/fingerprintsignin?inviteId=${this.uuid}`;
      }
      this.$emit('subModalOpen', false);
      this.$modal.show(
        EmailPreviewModal,
        {
          emailApiPayload: {
            type: 'send-fingerprint-request-new',
            meta: {
              audience: this.firstName,
              requester,
              toAddress: this.email,
              fpLink: this.fpTestLink,
            },
          },
          heading: 'Add new audience email preview',
        },
        {
          name: 'EmailPreviewModal',
          classes: 'v-application',
          width: '520px',
          height: '600px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
      trackGeneralEvents.addnewaudienceContinuePreviewemail(
        this.currentUser,
        this.trackingData,
      );
    },
    async openShareMenu() {
      trackGeneralEvents.addnewaudienceLearnmore(
        this.currentUser,
        {},
        this.origin,
      );
      await getDonationDetails()
        .then(async (res) => {
          if (res.data.contributors && res.data.contributors.length > 0) {
            await res.data.contributors.forEach(async (learner) => {
              learner.profileImageUrl = '';
              if (learner.profileImage && !learner.profileImageUrl) {
                getFileURL(
                  null,
                  `public/${learner.profileImage}`,
                  null,
                  'storage',
                ).then((s3url) => {
                  learner.profileImageUrl = s3url;
                });
              }
            });
          }
          this.contributors = res.data.contributors;
          this.totalCount = Number(res.data.totalCount);
        })
        .catch((err) => console.log(err));
      this.$emit('subModalOpen', false);
      this.$modal.show(
        LearnMorePop,
        {
          contributors: this.contributors,
          totalCount: this.totalCount,
          onVisitSite: () => {
            trackGeneralEvents.addnewaudienceLearnmoreVisitsite(
              this.currentUser,
              {},
              this.origin,
            );
          },
        },
        {
          name: 'LearnMorePop',
          classes: 'v-application',
          width: '520px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },

    ...mapActions('users', ['setCurrentPrivateAudience', 'setCurrentUser']),

    async createGroupPrivateAudience(isZenSense = false) {
      try {
        const email = this.email
          ? this.email
          : `${this.getRandomString(6)}@${
              this.currentUser.user.id.split('@')[1]
            }`;
        AnalyticsHandler.addPrivateGroupAudience(
          this.currentUser,
          this.firstName,
        );
        const groupObject = {
          id: email,
          userID: this.currentUser.user.id,
          firstName: this.firstName,
          lastName: 'Group',
          username: email,
          companyName: this.audienceCompanyName,
          emailEntered: !!this.email,
          ...(isZenSense && { fingerprintType: 'Zensense' }),
        };
        await this.invokePrivateAudienceAPI(groupObject);
        if (this.step !== 5) {
          this.trackingData = {
            ...groupObject,
            type: TD_AUDIENCE_TYPE_GROUP,
            method: isZenSense
              ? TD_AUDIENCE_ADD_TYPE_ZENSENSE
              : TD_AUDIENCE_ADD_TYPE_COMPLETE,
            origin: this.origin,
          };
          trackGeneralEvents.addnewaudienceContinue(
            this.currentUser,
            this.trackingData,
          );
          this.$router.push(
            `/welcome?&name=${encodeURIComponent(
              this.firstName,
            )}&email=${email}&initialIndex=1&requester=${
              this.currentUser.user.id
            }&origin=${this.origin}&zensense=${isZenSense}`,
            () => {},
          );
          this.handleClose();
        }
        this.loading = false;
      } finally {
        console.log('success');
      }
    },
    async createIndividualPrivateAudience(isZenSense = false) {
      try {
        const privateUserbject = {
          id: this.email,
          userID: this.currentUser.user.id,
          firstName: this.firstName,
          lastName: this.lastName ? this.lastName : 'Group',
          companyName: this.audienceCompanyName,
          ...(isZenSense && { fingerprintType: 'Zensense' }),
        };
        await this.invokePrivateAudienceAPI(privateUserbject);
        this.loading = false;
        if (this.step !== 5) {
          AnalyticsHandler.performAddPrivateAudience(
            this.currentUser,
            this.email,
            this.firstName,
            this.lastName,
          );

          this.trackingData = {
            ...privateUserbject,
            type: TD_AUDIENCE_TYPE_INDIVIDUAL,
            method: isZenSense
              ? TD_AUDIENCE_ADD_TYPE_ZENSENSE
              : TD_AUDIENCE_ADD_TYPE_COMPLETE,

            origin: this.origin,
          };

          trackGeneralEvents.addnewaudienceContinue(
            this.currentUser,
            this.trackingData,
          );
          this.$router.push(
            `/welcome?&name=${encodeURIComponent(this.firstName)}&email=${
              this.email
            }&initialIndex=1&requester=${this.currentUser.user.id}&origin=${
              this.origin
            }&zensense=${isZenSense}`,
            () => {},
            () => {},
          );
          this.handleClose();
        }
      } finally {
        this.loading = false;
        console.log('success');
      }
    },

    async invokePrivateAudienceAPI(payload) {
      await AudienceAPI.methods
        .createPrivateAudience(payload)
        .then(async (resp) => {
          this.setCurrentPrivateAudience(resp.privateAudience);
        })
        .catch((error) => {
          const errorMessage = error?.response?.data?.error;
          // in case private audience already exist allow update
          // and not show already exist pop-up
          if (
            !errorMessage ||
            !errorMessage.toLowerCase().includes('already exist')
          ) {
            this.step = 5;
          }
          console.log(error.response);
        });
    },

    async createIndividualAudience() {
      this.loading = true;
      const audienceDetails = {
        id: this.email,
        userID: this.currentUser.user.id,
        firstName: this.firstName,
        lastName: this.lastName,
        companyName: this.audienceCompanyName,
      };
      await AudienceAPI.methods.createAudienceUser(audienceDetails).then(
        (response) => {
          this.trackingData = {
            ...audienceDetails,
            type: TD_AUDIENCE_TYPE_INDIVIDUAL,
            method: TD_AUDIENCE_ADD_TYPE_REQUEST,
            origin: this.origin,
          };

          trackGeneralEvents.addnewaudienceContinue(
            this.currentUser,
            this.trackingData,
          );

          if (!response.companyExist) {
            this.step = 7;
          } else if (response.fpAlreadyExist) {
            this.audienceResponse = response;
            this.step = 4;
          } else {
            this.step = 2;
            this.uuid = response.audience.uuid;
            if (window.location.origin.includes('localhost')) {
              this.fpTestLink = `${window.location.origin}/fingerprintsignin?inviteId=${this.uuid}`;
            } else {
              const appDomain = process.env.VUE_APP_DOMAIN;
              this.fpTestLink = `${appDomain}/fingerprintsignin?inviteId=${this.uuid}`;
            }
          }
          this.loading = false;
        },
        (errResponse) => {
          // API returns 409 if the user exists and has fingerprint
          if (errResponse.response.status === 409) {
            this.step = 5;
          } else {
            console.error(
              'Unknown error while creating audience user',
              errResponse,
            );
          }
        },
      );
      this.loading = false;
    },

    async handleRequestFingerprint() {
      if (
        isEmojiExist(this.email) ||
        isEmojiExist(this.firstName) ||
        isEmojiExist(this.lastName)
      ) {
        return;
      }
      this.loading = true;
      this.createIndividualAudience();
    },

    async handleCompleteAssessment() {
      if (
        isEmojiExist(this.email) ||
        isEmojiExist(this.firstName) ||
        isEmojiExist(this.lastName)
      ) {
        return;
      }

      this.loading = true;
      if (this.audienceType === 'group') {
        await this.createGroupPrivateAudience(
          this.addAudienceBy === 'zensense',
        );
      } else {
        await this.createIndividualPrivateAudience(
          this.addAudienceBy === 'zensense',
        );
      }

      this.loading = false;
    },
    handleCopyLink() {
      const textToCopy = this.$refs.textToCopy.$el.querySelector('input');
      textToCopy.select();
      document.execCommand('copy');
      this.isCopied = true;
      AnalyticsHandler.fingerprintCopiedLink(
        this.currentUser,
        this.email,
        this.firstName,
        this.lastName,
      );
      PendoAnalyticsHandler.publicAudienceAdded({
        user: this.currentUser,
        origin: this.origin,
      });
      trackGeneralEvents.addnewaudienceContinueAssessmentcopylink(
        this.currentUser,
        this.trackingData,
      );

      try {
        const requester = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;
        const sub =
          `Hi Success Team,` +
          `${requester} (${this.currentUser.user.id}) ` +
          ` has copied audience invite link`;

        sendEmail({
          type: 'email-share-request-new',
          meta: {
            subject: sub,
            audience: 'Success Team',
            requester,
            toAddress: 'test.trial@prezent.ai',
            shareLink: this.shareTrialLink,
            message:
              `${requester} (${this.currentUser.user.id}) ` +
              ` has copied audience invite link for ${this.email}`,
          },
        })
          .then((resp) => {
            console.log(resp);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    handleClose() {
      this.$modal.hide('AddCollegueDetail');
      this.close();
    },
    handleNextClick(step, isRedo) {
      console.log(step);
      console.log(isRedo);
      this.step = 1;
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.audienceType = 'individual';
      this.audienceCompanyName = '';
      this.addAudienceBy = null;
      this.audienceAlreadyCreated = false;
    },
    async getExistingAudience(email) {
      const existingAudience = await AudienceAPI.methods
        .getAudienceById(email)
        .catch((e) => {
          console.log(e);
          return null;
        });
      if (existingAudience) {
        return {
          details: existingAudience,
        };
      }
      return null;
    },
    getRandomString(bytes) {
      const randomValues = new Uint8Array(bytes);
      window.crypto.getRandomValues(randomValues);
      return Array.from(randomValues).map(this.intToHex).join('');
    },
    intToHex(nr) {
      return nr.toString(16).padStart(2, '0');
    },
    async handleSendAssessment() {
      if (
        isEmojiExist(this.email) ||
        isEmojiExist(this.firstName) ||
        isEmojiExist(this.lastName)
      ) {
        return;
      }
      this.loading = true;
      const domainTargetUser = this.email.split('@')[1];
      if (
        this.getCompanyDomain.includes(
          domainTargetUser.substring(domainTargetUser.lastIndexOf('@') + 1),
        )
      ) {
        this.step = 2;
      }
      this.loading = false;

      this.isSent = true;
      const requester = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;
      if (window.location.origin.includes('localhost')) {
        this.fpTestLink = `${window.location.origin}/fingerprintsignin?inviteId=${this.uuid}`;
      } else {
        const appDomain = process.env.VUE_APP_DOMAIN;
        this.fpTestLink = `${appDomain}/fingerprintsignin?inviteId=${this.uuid}`;
      }
      sendEmail({
        type: 'send-fingerprint-request-new',
        meta: {
          audience: this.firstName,
          requester,
          toAddress: this.email,
          fpLink: this.fpTestLink,
        },
      })
        .then((resp) => {
          console.log(resp);
          trackGeneralEvents.addnewaudienceContinueSendemail(
            this.currentUser,
            this.trackingData,
          );
        })
        .catch((err) => {
          console.log(err);
        });
      AnalyticsHandler.fingerprintEmailedLink(
        this.currentUser,
        this.email,
        this.firstName,
        this.lastName,
      );
      PendoAnalyticsHandler.publicAudienceAdded({
        user: this.user,
        origin: this.origin,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

::v-deep .v-input--selection-controls {
  margin-top: 16px !important;
  padding-top: 4px !important;
}

::v-deep .v-text-field {
  padding-top: 12px !important;
  margin-top: 4px !important;
}

::v-deep .v-input--selection-controls__input {
  margin-right: 8px;
}

.offscreen {
  position: absolute;
  left: -999em;
}

.wrapper {
  align-items: flex-start;
  background: white;
  display: flex;
  flex-direction: column;
  font-family: 'Lato' !important;
  font-size: 16px;
  justify-content: space-between;
  min-height: 100%;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}
.content-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
  .send-request__img {
    margin-top: 8px;
    max-height: 132px;
    max-width: 500px;
  }
  .existing-user__screen {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 120px;
    padding-top: 16px;
    text-align: center;

    .existing-user__title {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .send-options {
    border-radius: 12px;
    box-shadow: $elevation-01;
    margin-left: 5px !important;
    margin-right: 15px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .send-options__button {
      margin: 0 auto;
      max-width: 154px;
    }
    .preview-email {
      color: #21a7e0;
      cursor: pointer;
      text-align: center;
      margin: 0px 0px 10px 0px;
    }
    .send-title {
      padding: 0px 10px;
    }
    .copy-title,
    .send-title {
      font-size: 16px;
      padding-bottom: 24px;
      text-align: center;
    }
  }
  .add-audience__title {
    font-family: 'Lato' !important;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.01em;
    padding: 1.25rem auto 1rem;
  }
  .audience-type__title {
    font-family: 'Lato';
    font-weight: 700;
    line-height: 24px;
    padding-top: 1.25rem;
  }
  .option-description {
    align-items: center;
    display: flex;
    gap: 4px;
  }
  .option-description__disabled {
    color: $darker-gray !important;
  }
  ::v-deep .v-input--selection-controls .v-radio > .v-label {
    color: black;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
  ::v-deep.v-radio .v-icon {
    &.info-icon {
      color: $gray-dark-login-text !important;
      font-size: 1rem;
    }
  }
  .content-label {
    font-family: 'Lato';
    font-weight: 700;
    line-height: 24px;
  }
  .group-content-panel {
    display: flex;
    margin: -12px;
    > div {
      flex: 0 0 50%;
      padding: 12px;
    }
  }
  .action-wrapper {
    display: flex;
    flex-direction: row;
  }
  .align-btn-left {
    flex-direction: row;
  }
  .second-screen {
    height: 400px;
    padding-top: 30px;
  }
}
.first-screen {
  display: flex;
  flex-direction: column;
}
.popper {
  padding: 16px;
  .first-screen {
    height: 610px;
  }
}
.sub-text {
  color: black;
  font-size: 16px;
}
.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}
.error-content__description {
  display: flex;
  flex-direction: column;
  line-height: 130%;
  padding: 8px 0 30px;
  text-align: center;
}
.error-content {
  color: black;
  font-size: 18px;
  .img-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.reminder-img {
  margin: 15px;
}

.small-logo {
  margin-left: 1px;
  width: 96px;
}
.add-heart__title {
  font-size: 14.5px;
  margin-top: 5px;
  color: gray;
  display: flex;
  .add-heart__title_subtext {
    margin-top: -3.5px;
    margin-left: 5px;
  }
}
.learn_more {
  cursor: pointer;
  color: rgb(0, 149, 255);
  margin-left: 21px;
}
.v-menu__content {
  width: 490px;
  right: 20px;
  background: white;
  padding: 20px;
  border-radius: 15px;
}
</style>
