<template>
  <div class="my-req-wrapper">
    <div
      class="my-request-container"
      :class="showQuickSelection ? 'quick-panel-active' : ''"
    >
      <div
        :class="`filter-container${viewMode !== 'mosaic' ? '-list-view' : ''}`"
      >
        <v-text-field
          v-model="searchTerm"
          solo
          rounded
          class="text-field"
          data-pendo-id="my-request-search"
          hide-details
          :placeholder="$t('overnightPresentations.searchByRequest')"
          @input="handleSearch"
          clearable
          :clear-icon="'mdi-close'"
          @focus="searchWithTextBoxActive = true"
          @blur="searchWithTextBoxActive = false"
          @keyup="debounceInputChange"
        >
          <template #append>
            <v-icon
              class="icon"
              v-text="`mdi-magnify`"
              :color="searchWithTextBoxActive === true ? '#21a7e0' : 'inherit'"
            />
          </template>
        </v-text-field>

        <div class="quick-action-cta">
          <template v-if="showQuickSelection || !leftSideBarStatus">
            <v-tooltip
              top
              max-width="250"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                  class="filter-btn"
                  :class="toolType === 'template' ? 'filter-btn-active' : ''"
                  data-pendo-id="my-request-template-btn"
                  @click="handleQuickSelection('template')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <img src="/assets/img/template.svg" alt />
                  <span>Template</span>
                </button>
              </template>
              <span>{{ quickSelectionTemplates }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            <button
              class="filter-btn"
              :class="toolType === 'template' ? 'filter-btn-active' : ''"
              data-pendo-id="my-request-template-btn"
              @click="handleQuickSelection('template')"
            >
              <img src="/assets/img/template.svg" alt />
              <span>Template: {{ quickSelectionTemplates }}</span>
            </button>
          </template>
          <button
            class="filter-btn"
            :class="toolType === 'filter' ? 'filter-btn-active' : ''"
            data-pendo-id="my-request-filter-btn"
            @click="handleQuickSelection('filter')"
          >
            <img src="/assets/img/filter-sort.svg" alt />
            Filter & Sort
          </button>
        </div>
      </div>
      <div class="controls-wrapper">
        <div class="view-controls">
          <div class="view-mode list" @click="handleViewMode('list')">
            <img
              v-if="viewMode === 'list'"
              src="/assets/img/list-view-active.svg"
              alt
            />
            <img v-else src="/assets/img/list-view.svg" alt />
            {{ $t('overnightPresentations.listView') }}
          </div>
          <div class="view-mode mosaic" @click="handleViewMode('mosaic')">
            <img
              v-if="viewMode === 'mosaic'"
              src="/assets/img/mosaic-view-active.svg"
              alt
            />
            <img v-else src="/assets/img/mosaic-view.svg" alt />
            {{ $t('overnightPresentations.mosaicView') }}
          </div>
        </div>
        <div class="advanced-filter-controls">
          <v-icon
            :class="
              advancedFilterOpened ? 'advancedActive' : 'advancedInActive'
            "
            v-tooltip="{
              content: 'Advanced Filters',
              placement: 'left-center',
              delay: {
                show: 500,
                hide: 300,
              },
            }"
            color="black"
            data-pendo-id="opAdvanceFilterAction"
            @click="handleOpenAdvancedFilter"
          >
            mdi-tune
          </v-icon>
        </div>
      </div>
      <div
        ref="myRequestScroll"
        class="op-requests-container"
        v-scroll="onScroll"
      >
        <div
          class="op-requests-content"
          :class="{ 'filters-opened': advancedFilterOpened }"
        >
          <template v-if="(opItems && opItems.length > 0) || loading">
            <div class="op-mosaic-view" v-if="viewMode === 'mosaic'">
              <div class="spinnerContainer" v-if="loading && page === 0">
                <v-progress-circular indeterminate :size="50" color="#21a7e0" />
              </div>
              <v-item-group active-class="primary" class="text-center" v-else>
                <v-container fluid>
                  <v-row>
                    <v-col
                      v-for="(oitm, i) in opItems"
                      :key="`${oitm.ticketId}__${i}`"
                      class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3"
                      :style="[
                        $vuetify.breakpoint.xs ||
                        $vuetify.breakpoint.sm ||
                        $vuetify.breakpoint.md
                          ? { 'min-width': '300px' }
                          : {},
                      ]"
                    >
                      <v-item v-slot="{ active, toggle }">
                        <OvernightPrezentationCard
                          @click.native="selectPrezentation(oitm, 'Mosaic')"
                          @download="download"
                          @onRatingUpdate="onRatingUpdate"
                          :active="active"
                          :toggle-fn="toggle"
                          class="op-card"
                          :prezentation="oitm"
                          :data-pendo-id="i === 0 ? 'opFirstRequest' : null"
                          :isFirstRequest="i === 0 ? true : false"
                        />
                      </v-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-item-group>
              <div
                class="see-more-btn-container"
                v-if="
                  (!loading || (loading && page > 0)) &&
                  opItems.length < totalCount
                "
              >
                <v-btn
                  class="my-4"
                  style="text-transform: none"
                  @click="loadNext"
                  color="#21a7e0"
                  rounded
                  outlined
                  height="32"
                  :loading="loading"
                  :disabled="loading || opItems.length >= totalCount"
                >
                  {{
                    opItems.length >= totalCount
                      ? $t('bestPracticesGuide.knowContainer.noMoreResults')
                      : $t('bestPracticesGuide.knowContainer.seeMoreResults')
                  }}
                </v-btn>
              </div>
            </div>
            <div class="op-list-view" v-else>
              <v-data-table
                :loading="loading"
                :headers="listHeaders"
                :items="listOPItems"
                :page="page + 1"
                :items-per-page="limit"
                :sort-by.sync="selectedFilter.field"
                :sort-desc.sync="selectedFilter.comparator"
                :server-items-length="totalCount"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15],
                }"
                @pagination="paginate($event)"
                @update:sort-by="getFilteredOPItems"
                @update:sort-desc="getFilteredOPItems"
                class="user-panel"
                :no-results-text="$t('build.step1.storylineFieldNoResult')"
                :loader-height="0"
              >
                <template v-slot:loading>
                  <clip-loader
                    :loading="true"
                    :color="`#21a7e0`"
                    :width="'60'"
                    :height="'60'"
                    :size="'40px'"
                    class="cliploader"
                  />
                </template>
                <template v-slot:[`item.thumbnail`]="{ item }">
                  <v-img
                    :src="
                      item.thumbnailImageURL
                        ? item.thumbnailImageURL
                        : '/assets/img/op-thumbnail.png'
                    "
                    class="list-view-preview"
                    alt
                    @click="selectTicket(item)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey"
                        ></v-progress-circular>
                      </v-row> </template
                  ></v-img>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div class="opName">
                    <NameEdit
                      :countNewComments="0"
                      :on-edit-fn="(val) => onUpdate(val, item)"
                      :on-select-fn="() => selectTicket(item)"
                      :name="item.name"
                    ></NameEdit>
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div v-html="getDateFunction(item.createdAt)"></div>
                </template>
                <template v-slot:[`item.storyline`]="{ item }">
                  {{ item.storyline }}
                </template>
                <template v-slot:[`item.audienceFirstName`]="{ item }">
                  {{
                    item.audienceFirstName && item.audienceLastName
                      ? `${item.audienceFirstName} ${item.audienceLastName}`
                      : '-'
                  }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  {{ currentLevel(item).display }}{{ statusText(item) }}
                </template>
                <template v-slot:[`item.isLiked`]="{ item }">
                  <OpRatingMenu :opRequest="item" @update="onRatingUpdate" />
                  <span v-if="item.status !== 'Completed'">-</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <div class="list-view-actions">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a
                          href="#"
                          class="notification"
                          @click="goToConversationHistory(item)"
                        >
                          <v-icon
                            class="icon"
                            v-text="`mdi-message-text-clock-outline`"
                            color="#21a7e0"
                            size="20"
                            v-bind="attrs"
                            v-on="on"
                          />
                          <span v-if="getMessageCount(item)" class="badge">{{
                            getMessageCount(item)
                          }}</span>
                        </a>
                      </template>
                      <span>
                        {{ $t('overnightPresentations.manageRequest') }}
                      </span>
                    </v-tooltip>
                    <DownloadOpFileFromListView :opRequest="item" />
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="item.status === 'Completed'"
                          class="icon"
                          v-text="`mdi-sync`"
                          color="#21a7e0"
                          :disabled="
                            !!getOPLimitedBanner ||
                            (getOPAccessLevel !== 'full_access' &&
                              getOPAccessLevel !== 'restricted_access') ||
                            isLimitExceeded
                          "
                          @click.stop="handleChangeOpRequest(item)"
                          size="20"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span>
                        {{ $t('overnightPresentations.changeRequest') }}
                      </span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </div>
          </template>
          <div v-else>
            <EmptyState
              img-url="/assets/img/view-prez-no-prezentations-yet.svg"
              :description="'overnightPresentations.noRequestsFound'"
              :is-btn-visible="true"
              :is-btn-disabled="disableNewReq"
              :handleCTA="submitNewRequest"
              :button-name="'overnightPresentations.submitNewRequest'"
            />
          </div>
        </div>

        <div id="go-top" class="go-top" v-show="showGoToTop" @click="toTop">
          <v-icon color="#fff" size="15" class="top-arrow">mdi-arrow-up</v-icon>
          <span>{{ $t('common.backToTop') }}</span>
        </div>
      </div>
    </div>

    <QuickSelectionCntr
      class="quick-panel"
      v-if="showQuickSelection"
      :toolType="toolType"
      :allThemes="allThemes"
      :handleSearchWithTemplateModelChange="handleSearchWithTemplateModelChange"
      :handleAllTemplatesClick="handleAllTemplatesClick"
      :handleOtherTemplatesClick="handleOtherTemplatesClick"
      @close="handleQuickSelection"
    >
      <OpAdvancedFilters />
    </QuickSelectionCntr>
  </div>
</template>
<script>
// import { Storage } from 'aws-amplify';
import { mapActions, mapGetters, mapState } from 'vuex';
// import { sortBy } from 'lodash';
import debounce from 'lodash/debounce';
import _ from 'lodash';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  getOPTickets,
  downloadFinalOPPPT,
  logOPDownload,
  updateOPTicket,
  getOPTicketsMessage,
  getFeatureUsage,
  postFeatureUsage,
} from '../../../utils/api-helper';
import { downloadFileWithCustomName } from '../../../utils/common';
import EventBus from '../../common/event-bus';
import EmptyState from '../../common/EmptyState.vue';
import ManageFiles from './ManageFiles.vue';
import OvernightPrezentationCard from './OvernightPrezentationCard.vue';
import OpRatingMenu from './OpRatingMenu.vue';
import RequestChangeModal from './RequestChangeModal.vue';
import NameEdit from '../Prezentations/NameEdit.vue';
import DownloadOpFileFromListView from './DownloadOpFileFromListView.vue';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_MOSAIC,
  TD_LIST,
  TD_LIST_VIEW,
  TD_FROM_TO,
  TD_ISDOWNLOAD,
  TD_MY_DOWNLOADS,
  NA,
  TD_QUERY,
  TD_SECTION_CHANGED,
  TD_SORTTYPE,
  TD_VIEWTYPE,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_PLACEMENT,
  TD_PREZENTATION_CATEGORY,
  TD_THUMBNAIL_VIEW,
  TD_INSTRUCTIONS,
  TD_AUD,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import QuickSelectionCntr from '../../common/QuickSelectionTools/QuickSelectionCntr.vue';
import OpAdvancedFilters from '../../common/QuickSelectionTools/Filter&Sort/OpAdvancedFilters.vue';

const customTemplate = {
  code: 'custom',
  name: 'Uploaded by me',
  thumbnailURL: '/assets/img/Custom_Template.svg',
  checked: true,
  disabled: true,
};

const headers = [
  {
    text: 'Preview',
    value: 'thumbnail',
    sortable: false,
    width: '200px',
  },
  { text: 'Prezentation name', value: 'name', width: '200px' },
  { text: 'Storyline', value: 'storyline', width: '200px' },
  { text: 'Submitted on', value: 'createdAt', width: '150px' },
  { text: 'Audience', value: 'audienceFirstName', width: '180px' },
  { text: 'Rating', value: 'isLiked', sortable: true, width: '100px' },
  { text: 'Status', value: 'status', width: '185px' },
  { text: 'Actions', value: 'actions', sortable: false, width: '140px' },
];

const handleDebounce = debounce((callback) => {
  callback();
}, 1000);

export default {
  name: 'MyRequest',
  data() {
    return {
      intervalId: null,
      searchWithTextBoxActive: false,
      loading: true,
      opItems: [],
      searchTerm: '',
      templateAllFilter: { name: 'All', checked: true, disabled: false },
      selectedTemplates: [{ name: 'All', checked: true, disabled: false }],
      allThemes: [],
      viewMode: 'mosaic',
      listHeaders: headers,
      statuses: [
        {
          value: 'submitted',
          display: 'Submitted',
          image: '/assets/img/overnight-prezentations/op-submitted.png',
          icon: '/assets/img/overnight-prezentations/op-submitted-icon.svg',
          level: 0,
          text: 'Our team of designers is reviewing your request.',
          subtext:
            'We will notify you over email with an ETA and let you know if we have any questions.',
        },
        {
          value: 'accepted',
          display: 'Accepted',
          image: '/assets/img/overnight-prezentations/op-accepted.png',
          icon: '/assets/img/overnight-prezentations/op-accepted-icon.svg',
          level: 1,
          text: 'Great news! Our team is getting ready to start work on your request.',
        },
        {
          value: 'in progress',
          display: 'In Progress',
          image: '/assets/img/overnight-prezentations/op-in-progress.png',
          icon: '/assets/img/overnight-prezentations/op-in-progress-icon.svg',
          level: 2,
          text: 'Our team is working hard to meet your expectations. Hope you are getting some well-deserved rest!',
        },
        {
          value: 'completed',
          display: 'Completed',
          image: '/assets/img/overnight-prezentations/op-completed.png',
          icon: '/assets/img/overnight-prezentations/op-completed-icon.svg',
          level: 3,
          text: 'Yay! Your Overnight Prezentation is ready!',
        },
      ],
      page: 0,
      limit: 15,
      offsetTop: 0,
      totalCount: 0,
      showQuickSelection: false,
      toolType: null,
    };
  },
  components: {
    OvernightPrezentationCard,
    OpRatingMenu,
    EmptyState,
    NameEdit,
    ClipLoader,
    DownloadOpFileFromListView,
    QuickSelectionCntr,
    OpAdvancedFilters,
  },
  methods: {
    ...mapActions('users', [
      'setFilteredThemes',
      'setOPTicketUnreadMessageData',
      'setLimitData',
      'setUpgradePopup',
      'setTemplateToolPlaceHolder',
    ]),
    ...mapActions('advancedFilterStore', [
      'setAdvancedFilterToggle',
      'resetFilters',
      'getCurrentFormattedDate',
      'resetDate',
      'setOpAdvancedItems',
    ]),

    ...mapActions('commonDownloads', ['setNavFromDownloadSnackbarOP']),

    getSelectedTemplatesLabel() {
      let placeholderText = '';
      if (this.selectedTemplates.length === 0) {
        placeholderText = '';
        this.setTemplateToolPlaceHolder('None');
      } else {
        if (
          this.selectedTemplates.length === 1 &&
          this.selectedTemplates[0].checked
        )
          placeholderText = this.selectedTemplates[0].name;
        if (this.selectedTemplates.length > 1)
          placeholderText = `${this.selectedTemplates[0].name} (+ ${
            this.selectedTemplates.length - 1
          } more)`;
        this.setTemplateToolPlaceHolder(placeholderText);
      }
      return placeholderText;
    },
    goToConversationHistory(item) {
      this.$router.push({
        path: `/home/my-overnight-prezentation/${item?.ticketId}?origin=overnight`,
      });
      trackOvernightPrezentationsEvents.OPMyRequestsConvertionHistory(
        this.currentUser,
        {
          [TD_AUD]: item?.audienceEmail,
          [TD_VIEWTYPE]: TD_LIST,
          [TD_PREZNAME]: item?.name ? item?.name : NA,
          [TD_PREZTYPE]: item?.storyline ? item?.storyline : NA,
          [TD_PLACEMENT]: TD_LIST_VIEW,
          [TD_PREZENTATION_CATEGORY]: item?.status ? item?.status : NA,
        },
      );
    },
    getMessageCount(ticket) {
      const getTicket = this.opUnreadMessageData?.find(
        (item) => item?.ticketId === ticket?.ticketId,
      );
      if (getTicket) return getTicket?.unreadCount;
      return 0;
    },
    dateFormatter(data) {
      const date = new Date(data);
      // return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
      return date.toLocaleDateString('en-US');
    },
    submitNewRequest() {
      this.$router.push({
        path: `/home/overnight-prezentations?tab=newRequest`,
      });
    },
    onScroll(event) {
      this.offsetTop = event.target.scrollTop;
    },
    // eslint-disable-next-line func-names
    debounceInputChange: _.debounce(function (e) {
      const searchText = e?.target?.value;
      if (searchText.length) this.inputChange(searchText);
    }, 300),
    inputChange(text) {
      trackOvernightPrezentationsEvents.OPMyRequestsSearch(this.currentUser, {
        [TD_QUERY]: text,
      });
    },
    async onUpdate(newName, presentation) {
      // if (newName === presentation.name) {
      //   presentation.name = newName;
      //   return;
      // }
      const payload = {
        ticketId: presentation.ticketId,
        name: newName,
      };
      presentation.name = newName;
      await updateOPTicket(payload)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      trackOvernightPrezentationsEvents.OPMyRequestsRename(this.currentUser, {
        [TD_AUD]: presentation?.audienceEmail,
        [TD_VIEWTYPE]: TD_LIST,
        [TD_PREZNAME]: newName,
        [TD_PREZTYPE]: presentation.storyline,
        [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
        [TD_PREZENTATION_CATEGORY]: presentation.status,
      });
    },
    toTop() {
      this.$refs.myRequestScroll.scrollTo({ top: 0, behavior: 'smooth' });
    },
    statusText(prezentation) {
      let textData = '';
      switch (prezentation?.status) {
        case 'Completed':
          textData = ` on ${this.dateFormatter(prezentation?.completionDate)}`;
          break;
        case 'Submitted':
          textData = ` on ${this.dateFormatter(prezentation?.createdAt)}`;
          break;
        default:
          break;
      }
      return textData;
    },
    handleChangeOpRequest(req) {
      this.$modal.show(
        RequestChangeModal,
        {
          opRequest: req,
          reqOrigin: 'myRequests',
          viewType: TD_LIST,
        },
        {
          name: 'RequestChangeModal',
          width: '650px',
          height: 'auto',
          styles: {
            'max-height': '75%',
            'max-width': '800px',
            overflow: 'auto',
          },
          clickToClose: true,
        },
      );
      trackOvernightPrezentationsEvents.OPMyRequestsChangeRequest(
        this.currentUser,
        {
          [TD_INSTRUCTIONS]: req?.feedback ? req?.feedback : NA,
          [TD_PREZNAME]: req.name,
          [TD_PREZTYPE]: req.storyline,
          [TD_VIEWTYPE]: TD_LIST,
          [TD_PREZENTATION_CATEGORY]: req?.status ? req?.status : NA,
        },
      );
    },
    selectPrezentation(item, viewType) {
      trackOvernightPrezentationsEvents.OPMyrequestsThumbnailClick(
        this.currentUser,
        {
          [TD_PREZNAME]: item.name,
          [TD_PREZTYPE]: item.storyline,
          [TD_VIEWTYPE]: viewType,
          [TD_PREZENTATION_CATEGORY]: item.status,
          [TD_AUD]: item.audienceId,
        },
      );
      this.$router.push({
        path: `/home/my-overnight-prezentation/${item.ticketId}?viewType=${viewType}`,
      });
    },

    selectTicket(value) {
      this.selectPrezentation(value, 'List');
    },
    handleOpenAdvancedFilter() {
      this.setAdvancedFilterToggle();
      if (this.advancedFilterOpened) {
        trackOvernightPrezentationsEvents.OPMyRequestsAdvanceFiltersClick(
          this.currentUser,
          {},
        );
        EventBus.$emit('MINIMIZE_LEFTNAV');
      }
    },
    updateFilterItems(items) {
      this.$store.dispatch('advancedFilterStore/setFilterItems', items);
    },
    updateSelectedFilter(items) {
      this.$store.dispatch('advancedFilterStore/setSelectedFilter', items);
    },
    // handleConversationHistory(id) {
    //   this.$router.push({
    //     path: `/home/my-overnight-prezentation/${id}?origin=overnight`,
    //   });
    //   const opItem = this.opItems.find((item) => item.ticketId === id);
    //   trackOvernightPrezentationsEvents.OPMyRequestsConvertionHistory(
    //     this.currentUser,
    //     {
    //       [TD_AUD]: opItem?.audienceEmail,
    //       [TD_VIEWTYPE]: TD_LIST,
    //       [TD_PREZNAME]: opItem?.name ? opItem?.name : NA,
    //       [TD_PREZTYPE]: opItem?.storyline ? opItem?.storyline : NA,
    //       [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
    //       [TD_PREZENTATION_CATEGORY]: opItem?.status ? opItem?.status : NA,
    //     },
    //   );
    // },
    handleSearch() {
      if (this.searchTerm && this.searchTerm.trim().length <= 3) return;
      const tempFilterItems = [
        { title: 'Most recent', field: 'createdAt', comparator: true },
        { title: 'Oldest first', field: 'createdAt', comparator: false },
        { title: 'Sort by A-Z', field: 'name', comparator: false },
        { title: 'Sort by Z-A', field: 'name', comparator: true },
      ];
      this.updateFilterItems(tempFilterItems);
      const tempSelectedFilter = {
        title: 'Most recent',
        field: 'createdAt',
        comparator: true,
      };
      this.updateSelectedFilter(tempSelectedFilter);
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];
      this.page = 0;
      this.resetAdvancedFilters();
    },
    handleAllOptionClickForCategories(item) {
      this.searchTerm = '';
      const tempFilterItems = [
        { title: 'Most recent', field: 'createdAt', comparator: true },
        { title: 'Oldest first', field: 'createdAt', comparator: false },
        { title: 'Sort by A-Z', field: 'name', comparator: false },
        { title: 'Sort by Z-A', field: 'name', comparator: true },
      ];
      this.updateFilterItems(tempFilterItems);
      const tempSelectedFilter = {
        title: 'Most recent',
        field: 'createdAt',
        comparator: true,
      };
      this.updateSelectedFilter(tempSelectedFilter);
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];

      item.items.forEach((i) => {
        i.active = item.checkAll;
      });
      this.page = 0;
      this.getFilteredOPItems();
    },
    onAdvancedFilerChange(item) {
      // resetting all other filter categories of the page
      this.searchTerm = '';
      const tempFilterItems = [
        { title: 'Most recent', field: 'createdAt', comparator: true },
        { title: 'Oldest first', field: 'createdAt', comparator: false },
        { title: 'Sort by A-Z', field: 'name', comparator: false },
        { title: 'Sort by Z-A', field: 'name', comparator: true },
      ];
      this.updateFilterItems(tempFilterItems);
      const tempSelectedFilter = {
        title: 'Most recent',
        field: 'createdAt',
        comparator: true,
      };
      this.updateSelectedFilter(tempSelectedFilter);
      this.templateAllFilter = { name: 'All', checked: true, disabled: false };
      this.selectedTemplates = [
        { name: 'All', checked: true, disabled: false },
      ];
      this.allThemes = [
        this.templateAllFilter,
        ...this.checkAndDisableAllValues([customTemplate, ...this.themes]),
      ];
      this.updateCurrentAdvancedFilter(item);
      this.page = 0;
      this.getFilteredOPItems();
      const isDownload = this.advancedItems.find(
        (filter) => filter.title === 'Downloads',
      );
      const fromTo =
        this.getFromDateStr && this.getToDateStr
          ? `${this.getFromDateStr} to ${this.getToDateStr}`
          : NA;
      const prezentationCategory = this.advancedItems.find(
        (filter) => filter.title === 'Status',
      );
      let details = '';
      if (prezentationCategory) {
        prezentationCategory?.items.forEach((filter) => {
          if (filter.active) {
            details += filter.title;
            details += '_';
          }
        });
      }
      trackOvernightPrezentationsEvents.OPMyRequestsAdvanceFiltersSelected(
        this.currentUser,
        {
          [TD_ISDOWNLOAD]: isDownload.items[0].active ? TD_MY_DOWNLOADS : NA,
          [TD_FROM_TO]: fromTo,
          [TD_COMMON_COLUMN_NAME]: TD_SECTION_CHANGED,
          [TD_COMMON_COLUMN_VALUE]: item.title,
          [TD_PREZENTATION_CATEGORY]: details.length ? details : NA,
        },
      );
    },
    checkOrUncheckAllOfCurrentAdvancedFilter(item) {
      return (
        item.items.filter((itm) => itm.active === true).length ===
        item.items.length
      );
    },
    updateCurrentAdvancedFilter(item) {
      // checking all option accordingly
      if (item.includeAllOption === true) {
        item = {
          ...item,
          checkAll:
            this.checkOrUncheckAllOfCurrentAdvancedFilter(item) === true,
        };
      }

      const tempAdvancedItems = [...this.advancedItems].map((itm) => {
        if (itm.title === item.title) {
          return {
            ...item,
          };
        }
        return {
          ...itm,
        };
      });

      this.setOpAdvancedItems(tempAdvancedItems);
    },
    loadNext() {
      this.page++;
      this.getFilteredOPItems();
    },
    paginate(options) {
      if (this.page === options.page - 1 && options.itemsPerPage === this.limit)
        return;
      if (options.itemsPerPage !== this.limit) {
        this.page = 0;
        this.limit = options.itemsPerPage;
      } else {
        this.page = options.page - 1;
      }
      if (
        this.opItems.length < options.page * this.limit &&
        this.opItems.length < this.totalCount
      ) {
        this.getFilteredOPItems();
      }
    },
    handleManageFiles(id, item) {
      this.$modal.show(
        ManageFiles,
        {
          id,
          request: item,
        },
        {
          name: 'ManageFiles',
          classes: 'v-application',
          width: '1020px',
          height: 'auto',
          styles: {
            borderRadius: '15px',
            'max-height': '80%',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
    handleViewMode(val) {
      this.loading = true;
      this.viewMode = val;
      this.page = 0;
      this.limit = 15;
      if (val === 'mosaic') {
        const tempSelectedFilter = {
          title: 'Most recent',
          field: 'createdAt',
          comparator: true,
        };
        this.updateSelectedFilter(tempSelectedFilter);
        trackOvernightPrezentationsEvents.OPMyRequestsMosaicViewClick(
          this.currentUser,
          {},
        );
      } else {
        const tempSelectedFilter = {
          title: 'Most recent',
          field: null,
          comparator: true,
        };
        this.updateSelectedFilter(tempSelectedFilter);
        trackOvernightPrezentationsEvents.OPMyRequestsListViewClick(
          this.currentUser,
          {},
        );
      }
      this.getFilteredOPItems();
    },
    handleAllTemplatesClick(item) {
      if (item.checked === false) {
        this.selectedTemplates = [];
        this.templateAllFilter = {
          name: 'All',
          checked: false,
          disabled: false,
        };
      } else {
        this.selectedTemplates = [
          { name: 'All', checked: true, disabled: false },
        ];
        this.templateAllFilter = {
          name: 'All',
          checked: true,
          disabled: false,
        };
      }
      const filteredThemes = [customTemplate, ...this.themes].map((thm) => ({
        ...thm,
        checked: item.checked,
        disabled: item.checked,
      }));
      this.allThemes = [this.templateAllFilter, ...filteredThemes];
      this.page = 0;
      this.resetAdvancedFilters();
    },
    handleSearchWithTemplateModelChange(e) {
      this.selectedTemplates = e;
      if (e.length > 0) {
        this.searchTerm = '';
        const tempFilterItems = [
          { title: 'Most recent', field: 'createdAt', comparator: true },
          { title: 'Oldest first', field: 'createdAt', comparator: false },
          { title: 'Sort by A-Z', field: 'name', comparator: false },
          { title: 'Sort by Z-A', field: 'name', comparator: true },
        ];
        this.updateFilterItems(tempFilterItems);
        const tempSelectedFilter = {
          title: 'Most recent',
          field: 'createdAt',
          comparator: true,
        };
        this.updateSelectedFilter(tempSelectedFilter);
        const themeCodes = e.map((tmp) => tmp.code);
        if (e.length === 1 && e[0].name === 'All') {
          const filteredThemes = [customTemplate, ...this.themes].map(
            (thm) => ({
              ...thm,
              checked: true,
              disabled: true,
            }),
          );
          this.allThemes = [
            { name: 'All', checked: true, disabled: false },
            ...filteredThemes,
          ];
        } else {
          const filteredThemes = [customTemplate, ...this.themes].map((thm) => {
            if (themeCodes.includes(thm.code)) {
              return {
                ...thm,
                checked: true,
                disabled: false,
              };
            }
            return {
              ...thm,
              checked: false,
              disabled: false,
            };
          });
          this.allThemes = [
            { name: 'All', checked: false, disabled: false },
            ...filteredThemes,
          ];
        }
        trackOvernightPrezentationsEvents.OPMyRequestsSelectTemplateSelected(
          this.currentUser,
          {
            [TD_QUERY]: '',
          },
        );
      } else {
        const filteredThemes = [customTemplate, ...this.themes].map((thm) => ({
          ...thm,
          checked: false,
          disabled: false,
        }));
        this.allThemes = [
          { name: 'All', checked: false, disabled: false },
          ...filteredThemes,
        ];
      }
      this.page = 0;
      this.resetAdvancedFilters();
    },
    async doSearch() {
      this.loading = true;
      if (this.advancedItems[0].items.filter((i) => i.active).length === 0) {
        this.opItems = [];
        this.loading = false;
      } else {
        const obj = {
          searchQuery: this.searchTerm || '',
          sortOrder: this.selectedFilter.comparator,
          sortColumn: this.selectedFilter.field,
          templateIds:
            !this.selectedTemplates.length ||
            this.selectedTemplates[0].name === 'All'
              ? []
              : this.selectedTemplates.map((i) => i.code),
          status: this.advancedItems[0].checkAll
            ? []
            : this.advancedItems[0].items
                .filter((i) => i.active)
                .map((i) => i.title),
          isDownloaded: this.advancedItems[1].items[0].active,
          startDate: this.getFromDateStr,
          endDate: this.getToDateStr,
          offset: this.page,
          limit: this.limit,
        };
        await getOPTickets(obj)
          .then(async (resp) => {
            this.totalCount = resp.data.total_count;
            const items = resp.data.tickets;
            if (this.page === 0) {
              this.opItems = [...this.formatOpRequests(items)];
            } else {
              this.opItems = [...this.opItems, ...this.formatOpRequests(items)];
            }
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.opItems = [];
            this.loading = false;
          });
      }
    },
    getFilteredOPItems() {
      this.loading = true;
      handleDebounce(() => this.doSearch());
    },
    async download(item) {
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response.data);
          }
        })
        .catch((error) => console.log(error));
      if (
        this.currentUser.user.role.toLowerCase() !== 'trialuser' ||
        (this.currentUser.user.role.toLowerCase() === 'trialuser' &&
          !this.limitsInfoObj?.slide?.isLimitCrossed)
      ) {
        postFeatureUsage({ feature: 'op', ticketId: item.ticketId })
          .then((response) => {
            console.log(response);
            if (response?.data) {
              this.setLimitData(response.data);
            }
          })
          .catch((error) => console.log(error));

        downloadFinalOPPPT(item.ticketId)
          .then((resp) => {
            console.log(resp);
            downloadFileWithCustomName(
              resp.attachmentUrl,
              resp.fileName,
              (val) => {
                if (!val) {
                  this.logDownload(item.ticketId);
                }
              },
              false,
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'op-slide' });
      }
    },
    logDownload(id) {
      logOPDownload(id)
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkAndDisableAllValues(data) {
      return data.map((item) => ({
        ...item,
        checked: true,
        disabled: true,
      }));
    },
    handleChangeFilter() {
      this.page = 0;
      this.getFilteredOPItems();
      trackOvernightPrezentationsEvents.OPMyRequestsSortBy(this.currentUser, {
        [TD_SORTTYPE]: this.selectedFilter.title,
        [TD_VIEWTYPE]: this.viewMode === 'mosaic' ? TD_MOSAIC : TD_LIST,
      });
    },
    getDateFunction(date) {
      const tempDate = new Date(date);
      return `${tempDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })}`;
    },
    handleOtherTemplatesClick(e) {
      this.allThemes = [...this.allThemes].map((thm) => {
        if (e.code === thm.code) {
          if (e.checked) {
            return {
              ...thm,
              checked: false,
              disabled: false,
            };
          }
          return {
            ...thm,
            checked: true,
            disabled: false,
          };
        }
        return {
          ...thm,
        };
      });
      const areAllTemplatesSelected =
        [...this.allThemes].filter(
          (itm) => itm.name !== 'All' && !itm.disabled && itm.checked === true,
        ).length ===
        this.allThemes.length - 1;
      if (areAllTemplatesSelected === true) {
        this.selectedTemplates = [
          { name: 'All', checked: true, disabled: false },
        ];
        this.templateAllFilter = {
          name: 'All',
          checked: true,
          disabled: false,
        };
        const filteredThemes = [customTemplate, ...this.themes].map((thm) => ({
          ...thm,
          checked: true,
          disabled: true,
        }));
        this.allThemes = [this.templateAllFilter, ...filteredThemes];
        this.handleSearchWithTemplateModelChange(this.selectedTemplates);
      }
    },
    onRatingUpdate(payload) {
      const { ticketId, feedback, isLiked } = payload;
      this.opItems = this.opItems.map((item) => {
        if (ticketId === item.ticketId) {
          item.feedback = feedback;
          item.isLiked = isLiked;
          trackOvernightPrezentationsEvents.OPMyRequestsRatePrezentation(
            this.currentUser,
            {
              [TD_AUD]: item?.audienceEmail,
              [TD_PREZNAME]: item?.name,
              [TD_PREZTYPE]: item?.storyline,
              [TD_VIEWTYPE]: this.viewMode === 'mosaic' ? TD_MOSAIC : TD_LIST,
              [TD_PLACEMENT]: TD_THUMBNAIL_VIEW,
              [TD_PREZENTATION_CATEGORY]: item?.status,
              [TD_INSTRUCTIONS]:
                payload?.feedback && payload?.feedback.length > 0
                  ? payload?.feedback
                  : NA,
            },
          );
        }
        return { ...item };
      });
    },
    resetAdvancedFilters(e) {
      this.resetFilters();
      this.getFilteredOPItems();
      if (e) {
        trackOvernightPrezentationsEvents.OPMyRequestsAdvanceFiltersReset(
          this.currentUser,
          {},
        );
      }
    },
    formatOpRequests(ops) {
      const opsReqs = [];
      ops.forEach((op) => {
        if (op.storyline) {
          opsReqs.push({
            ...op,
            storyline: op.storyline,
          });
        } else if (op.isCustomStoryline) {
          opsReqs.push({
            ...op,
            storyline: 'Custom',
          });
        } else {
          opsReqs.push({
            ...op,
            storyline: 'NA',
          });
        }
      });
      return opsReqs;
    },
    currentLevel(item) {
      return this.statuses.find((i) => i.value === item.status.toLowerCase());
    },
    refreshOpList() {
      this.page = 0;
      this.getFilteredOPItems();
    },
    handleQuickSelection(action) {
      EventBus.$emit('MINIMIZE_LEFTNAV');
      if (action && (this.toolType !== action || !this.toolType)) {
        this.toolType = action;
        this.showQuickSelection = true;
      } else if (this.toolType === action || !action) {
        this.toolType = null;
        this.showQuickSelection = false;
      }
    },

    handleLoadWhenNavFromDownloadSnackbar() {
      const advancedItemsWithMyDownloads = [...this.advancedItems].map(
        (item) => {
          if (item.title === 'Downloads') {
            return {
              ...item,
              checkAll: true,
              items: [
                {
                  active: true,
                  title: 'My Downloads',
                },
              ],
            };
          }
          return item;
        },
      );
      this.setOpAdvancedItems(advancedItemsWithMyDownloads);

      this.showQuickSelection = true;
      this.toolType = 'filter';
    },
  },
  watch: {
    themes: {
      handler(val) {
        this.allThemes = [
          this.templateAllFilter,
          customTemplate,
          ...this.checkAndDisableAllValues(val),
        ];
      },
      deep: true,
      immediate: true,
    },
    leftSideBarStatus(val) {
      if (!val) {
        this.showQuickSelection = false;
        this.toolType = '';
      }
    },
    selectedTemplates: {
      handler() {
        this.getSelectedTemplatesLabel();
      },
      deep: true,
      immediate: true,
    },

    getNavFromDownloadSnackbarOP(val) {
      if (
        val.navigated &&
        this.$route.fullPath === '/home/overnight-prezentations?tab=myRequest'
      ) {
        this.handleLoadWhenNavFromDownloadSnackbar();

        this.getFilteredOPItems();
        this.setNavFromDownloadSnackbarOP({ navigated: false, type: '' });
      }
    },
  },
  computed: {
    ...mapState('users', [
      'themes',
      'currentUser',
      'opUnreadMessageData',
      'limitsInfoObj',
      'leftSideBarStatus',
      'quickSelectionTemplates',
    ]),
    ...mapState('advancedFilterStore', ['advancedFilterOpened']),
    ...mapGetters('advancedFilterStore', ['getFromDateStr', 'getToDateStr']),
    ...mapGetters('users', ['getOPAccessLevel', 'getOPLimitedBanner']),
    ...mapGetters('commonDownloads', ['getNavFromDownloadSnackbarOP']),

    advancedItems: {
      get() {
        return this.$store.state.advancedFilterStore.op.advancedItems;
      },
      set() {},
    },
    filterItems: {
      get() {
        return this.$store.state.advancedFilterStore.op.filterItems;
      },
      set() {},
    },
    selectedFilter: {
      get() {
        return this.$store.state.advancedFilterStore.op.selectedFilter;
      },
      set() {},
    },
    backCta: {
      get() {
        return this.$store.state.advancedFilterStore.op.backCta;
      },
      set() {},
    },
    disableNewReq() {
      return (
        !!this.getOPLimitedBanner ||
        ['disabled', 'limited_access'].includes(this.getOPAccessLevel) ||
        (this.getOPAccessLevel === 'restricted_access' &&
          this.currentUser.user.OPRequestCount > 0)
      );
    },
    showGoToTop() {
      return this.offsetTop > 200;
    },
    listOPItems() {
      if (!this.loading) {
        return this.opItems.slice(
          this.page * this.limit,
          (this.page + 1) * this.limit,
        );
      }
      return [];
    },
    isLimitExceeded() {
      if (this.limitsInfoObj?.op?.access_level === 'full_access') {
        return false;
      }
      return this.limitsInfoObj?.op?.isLimitCrossed;
    },
  },
  async mounted() {
    EventBus.$on('GOTO_OP', (query) => {
      this.searchTerm = query;
    });

    // handling scenario where navigation is from snackbar items
    if (this.getNavFromDownloadSnackbarOP.navigated) {
      this.handleLoadWhenNavFromDownloadSnackbar();
      this.setNavFromDownloadSnackbarOP({ navigated: false, type: '' });
    } else if (!this.backCta) {
      this.resetAdvancedFilters();
    } else {
      this.$store.dispatch('advancedFilterStore/setBackCta', false);
    }

    if (this.themes.length) {
      this.setFilteredThemes(this.themes);
    }
    this.getFilteredOPItems();
    const result = await getOPTicketsMessage();
    this.setOPTicketUnreadMessageData(result?.data);
    this.intervalId = window.setInterval(async () => {
      const results = await getOPTicketsMessage();
      this.setOPTicketUnreadMessageData(results?.data);
    }, 30000);

    EventBus.$on('REFRESH_MY_OP_REQUESTS', this.refreshOpList);
    EventBus.$on('ALL_CATEGORY', this.handleAllOptionClickForCategories);
    EventBus.$on('RESET_ADVANCED_FILTERS', this.resetAdvancedFilters);
    EventBus.$on('ON_FILTER_CHANGE', this.onAdvancedFilerChange);
    EventBus.$on('CHANGE_SORT_FILTER', this.handleChangeFilter);
    this.$refs.myRequestScroll.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    EventBus.$off('REFRESH_MY_OP_REQUESTS', this.refreshOpList);
    EventBus.$off('ALL_CATEGORY', this.handleAllOptionClickForCategories);
    EventBus.$off('RESET_ADVANCED_FILTERS', this.resetAdvancedFilters);
    EventBus.$off('ON_FILTER_CHANGE', this.onAdvancedFilerChange);
    EventBus.$off('CHANGE_SORT_FILTER', this.handleChangeFilter);
    EventBus.$off('GOTO_OP');
    clearInterval(this.intervalId);
    this.$refs.myRequestScroll.removeEventListener('scroll', this.onScroll);
  },
};
</script>
<style lang="scss" scoped>
.my-req-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}
.quick-panel-active {
  width: calc(100% - 550px) !important;
}
.quick-panel {
  width: 550px !important;
}
.my-request-container {
  flex: 1;
  padding: 10px;
  .go-top {
    position: fixed;
    bottom: 6px;
    left: 48%;
    background-color: #21a7e0;
    padding: 3px 10px;
    cursor: pointer;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    color: #fff !important;
    font-size: 13px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-shadow: 0 -1px 5px 1px rgb(64 60 67 / 16%);
    // animation: fadein 2s linear 1 forwards;
    // opacity: 0;

    // &.show {
    //   animation: fadein 2s linear 1 forwards;
    //   opacity: 0;
    // }

    // &.hide {
    //   animation: fadein 2s linear 1 forwards;
    //   opacity: 0;
    // }

    .top-arrow {
      margin-right: 5px;
      margin-bottom: 3px;
      font-size: 14px;
    }
  }
}

.quick-selection-wrapper {
  height: calc(100vh - 150px);
  overflow: hidden;

  ::v-deep .quick-selection-cntr {
    margin-right: 10px;

    .advanced-filter-wrapper {
      height: calc(100vh - 250px) !important;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .advanced-filter-wrapper::-webkit-scrollbar {
      display: none;
    }

    .templates-cntr {
      max-height: calc(100vh - 320px);
    }
  }
}

.filter-container,
.filter-container-list-view {
  display: flex;
  margin-top: 10px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;

  .text-field {
    max-width: 485px;
    min-width: 380px;
    margin: 0px 15px 0px 0px;
    ::v-deep .v-input__slot {
      box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16) !important;
      font-size: 14px;
    }
  }
}

.quick-action-cta {
  display: flex;
  gap: 0.75rem;
}

.filter-btn {
  color: #21a7e0;
  border-radius: 10px;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.01em;
  text-align: center;
  height: 100%;
  min-width: 100px;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
}

.filter-btn-active {
  background-color: #e1f5fb;
}

.controls-wrapper {
  display: none;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;

  .view-controls {
    display: flex;
    gap: 24px;
    cursor: pointer;
  }

  .advanced-filter-controls {
    .advancedActive,
    .advancedInActive {
      cursor: pointer;
      margin-right: 15px;
    }
    .advancedActive {
      color: #21a7e0 !important;
    }
  }
}

.op-requests-container {
  display: flex;
  margin-top: 20px;
  padding: 0 20px;
  height: calc(100vh - 230px);
  overflow: scroll;

  .op-requests-content {
    width: 100%;

    .op-list-view {
      width: 100%;
      margin-bottom: 25px;
      .cliploader {
        margin: 100px auto;
      }
      ::v-deep .v-data-table td {
        border-bottom: none !important;
      }
      .v-data-table {
        ::v-deep .v-data-table__wrapper {
          table {
            thead {
              tr {
                th {
                  font-size: 16px !important;
                  font-weight: normal;
                  span {
                    color: #21a7e0 !important;
                  }
                }
              }
            }
            tbody {
              tr {
                height: 92px;
                td {
                  .v-image {
                    cursor: pointer;
                  }
                  .opName {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .list-view-preview {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 3.5969px;
        margin: 12px 4px;
        width: 120px;
      }

      .list-view-actions {
        display: flex;
        // width: 70px;
        // justify-content: space-between;
        .icon {
          cursor: pointer;
          padding: 0px 4px;
        }
        .notification {
          text-decoration: none;
          padding: 10px;
          position: relative;
          display: inline-block;
          ::v-deep .v-icon {
            color: #21a7e0 !important;
          }
        }

        .notification .badge {
          position: absolute;
          top: 5px;
          right: 3px;
          padding: 0px 5px;
          border-radius: 50%;
          background: #21a7e0;
          color: white;
          width: 17px;
          height: 17px;
          font-size: 11px;
        }
      }
    }

    &.filters-opened {
      width: 80%;
    }

    // .op-mosaic-view {
    //   display: flex;
    //   flex-wrap: wrap;
    //   gap: 30px;

    //   > div {
    //     max-width: calc(33% - 20px);
    //   }
    // }
  }
}
.spinnerContainer {
  margin: 135px auto;
  display: flex;
  justify-content: center;
}

.see-more-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slides-filter__sort,
.slides-filter__template {
  display: flex;
  align-items: baseline;
  margin-right: 15px;
  flex-direction: row;
  // .label {
  //   padding: 12px 12px 12px 0px;
  // }
}
.slides-filter__sort {
  .filter-by {
    flex: none;
    font-size: 14px;
    ::v-deep .v-text-field__details {
      display: none;
    }
    ::v-deep .v-input__slot {
      border-bottom: 0.8px solid #b6b6b6;
      box-shadow: none !important;
      .v-select__slot {
        input {
          padding: 8px 0px 0px 0px !important;
        }
      }
    }
  }
}
.slides-filter__template {
  width: 35%;
  min-width: 404px;
  .templates-dropdown {
    flex: none;
    width: calc(100% - 105px);
    font-size: 14px !important;
    font-weight: 400;
    line-height: 19px;
    flex: none;
    margin: 0;
    ::v-deep .v-input__control {
      min-height: 38px !important;
      .v-input__slot {
        margin: 0;
        border-bottom: 0.8px solid #b6b6b6 !important;
        border-radius: 0px 0px 4px 4px !important;
        padding: 0 12px !important;
        .v-select__slot {
          .v-select__selections {
            padding: 8px 0px 0px 0px;
            .selected-type {
              max-width: 57% !important;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .v-input__append-inner {
            padding: 10px 0px 0px 4px !important;
          }
        }
        &:before {
          border: none !important;
        }
        &:after {
          border: none !important;
        }
      }
      .v-text-field__details {
        display: none !important;
      }
    }
    ::v-deep .v-menu__content {
      .v-list {
        .v-list-item {
          padding: 0;
          min-height: auto;
          margin: 0px 0px 4px 0px;
          .template-list-item {
            width: 100% !important;
            .allOption {
              width: 100% !important;
              margin: 0 !important;
              padding: 0 !important;
              .v-input__control {
                .v-input__slot {
                  padding: 0 !important;
                  border-bottom: none !important;
                  .v-input--selection-controls__input {
                    margin: 16px 0px 16px 16px;
                  }
                  .v-label {
                    margin: 0px 0px 10px 8px;
                  }
                }
              }
            }
            .row {
              width: 100%;
              margin: 0 !important;
              .checkboxWithName {
                display: flex;
                width: 100%;
                .v-input {
                  margin: 0;
                  padding: 0;
                  .v-input__control {
                    .v-input__slot {
                      border-bottom: none !important;
                      padding: 0 !important;
                      .v-input--selection-controls__input {
                        margin: 0px 0px 0px 16px;
                      }
                      .v-label {
                        margin: 0px 0px 6px 8px;
                      }
                    }
                  }
                }
                .template-title {
                  margin: 8px 0px 0px 8px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .imagesRow {
                width: 100%;
                display: flex !important;
                height: 104px !important;
                padding: 0 12px;
                .available-theme-img {
                  padding: 8px 6px 8px 8px;
                }
                .example-slide-img {
                  padding: 8px 8px 8px 6px;
                }
                .available-theme-img,
                .example-slide-img {
                  width: 50%;
                  height: 100%;
                  .thumbnailWrapper {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    box-shadow: 0.413462px 0.413462px 1.65385px rgb(0 0 0 / 10%),
                      -0.413462px -0.413462px 1.65385px;
                    .v-image {
                      width: 100%;
                      height: 100%;
                      border-radius: 4px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .v-select-list {
        .no-templates-found {
          .no-result-text {
            margin: 0 !important;
            padding: 20px 0px 20px 20px !important;
            color: #000000 !important;
            font-family: 'Lato' !important;
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 16px !important;
            line-height: 19px !important;
          }
        }
      }
    }
  }
}
.empty-state__container {
  margin: 0;
}
</style>
