<template>
  <div class="node-count-container">
    <div class="heading">
      {{ $t('generate.editNodeCount') }}
      <!-- <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div> -->
    </div>
    <div class="sub-heading">
      {{ $t('slideDetails.selectNodeCountInfo') }}
    </div>
    <div v-if="isLoading">
      <clip-loader
        :loading="isLoading"
        :color="`#21a7e0`"
        :width="40"
        :height="40"
      />
    </div>
    <div class="node-content" v-if="!isLoading">
      <div
        v-for="(slide, index) in filteredSibilings"
        class="slide"
        :key="index"
      >
        <v-img
          contain
          lazy-src="/assets/img/slides/placeholder-slide.svg"
          :aspect-ratio="16 / 9"
          :src="slide.poster || '/assets/img/slides/placeholder-slide.svg'"
          loading="lazy"
          class="node-image"
          :class="{ current: slide.asset.prefs.node == nodeCount }"
          @click="checkReplaceVisualPopup(slide)"
        />
        <div class="node-count">
          {{ $t('generate.nodeCount') }}: {{ slide.asset.prefs.node }}
        </div>
      </div>
    </div>
    <ConfirmationDialog
      :show="showDialog"
      :on-primary-click="onDialogConfirm"
      :on-secondary-click="onDialogCancel"
      :on-close="onDialogCancel"
      :primary-btn-text="$t('common.yes')"
      :secondary-btn-text="$t('common.no')"
      :title="$t('prezentation.changeNodeConfirmTitle')"
      :text="$t('prezentation.changeNodeConfirmText')"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {
  getSiblings,
  getSlideDataForDetailView,
} from '../../../../utils/api-helper';
import ConfirmationDialog from '../../../common/ConfirmationDialog.vue';

export default {
  name: 'ChangeNode',
  components: {
    ClipLoader,
    ConfirmationDialog,
  },
  props: {
    updateSlide: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      nodeCount: '',
      siblings: [],
      slideType: '',
      isLoading: false,
      selectedSlideData: {},
      showReplaceVisualPopup: false,
      showDialog: false,
      selectedNodeSlide: {},
    };
  },
  computed: {
    ...mapState('prezentationDetails', ['prezentationData', 'selectedSlide']),
    ...mapState('users', ['currentUser']),
    filteredSibilings() {
      if (['thank_you', 'discussion_topic'].includes(this.slideType)) {
        return this.siblings.filter(
          // eslint-disable-next-line eqeqeq
          (slide) => slide.asset.prefs.node == this.nodeCount,
        );
      }
      return this.siblings;
    },
  },
  methods: {
    checkReplaceVisualPopup(slide) {
      this.selectedNodeSlide = slide;
      if (this.showReplaceVisualPopup) {
        this.showDialog = true;
      } else {
        this.handleNodeChange();
      }
    },
    onDialogConfirm() {
      this.handleNodeChange();
      this.showDialog = false;
      this.showReplaceVisualPopup = false;
    },
    onDialogCancel() {
      this.showDialog = false;
    },
    handleNodeChange() {
      if (this.nodeCount === this.selectedNodeSlide.asset.prefs.node) {
        return;
      }
      this.updateSlide(
        { type: 'node_change' },
        {
          ...this.selectedNodeSlide.asset,
          landscapePath: this.selectedNodeSlide.landscape,
        },
      );
      // this.$emit('change', { type: 'node_change', slide: slide.asset });
    },
    async fetchSlideMetaData() {
      this.selectedSlideData = {
        ...this.selectedSlide,
      };
      if (
        this.selectedSlide &&
        !(
          this.selectedSlide?.assetId?.includes('public/') ||
          this.selectedSlide?.assetId?.includes('private/') ||
          this.selectedSlide?.assetId?.includes('protected/')
        ) &&
        this.selectedSlide?.prefs?.is_know !== 'yes'
      ) {
        let slideExtraData = {};
        let { originalUniqueID } = this.selectedSlide;
        if (
          this.selectedSlide?.assetId?.includes('newImageReplaceOutput/') &&
          originalUniqueID
        ) {
          originalUniqueID = originalUniqueID?.includes('replaced-image/')
            ? originalUniqueID.replaceAll('replaced-image/', '')
            : originalUniqueID;
          await getSlideDataForDetailView(originalUniqueID).then((res) => {
            slideExtraData = res;
            this.selectedSlideData = {
              ...this.selectedSlide,
              ...slideExtraData.data,
            };
            this.getSiblingsData();
          });
        } else if (this.selectedSlide?.uniqueID) {
          await getSlideDataForDetailView(this.selectedSlide.uniqueID).then(
            (res) => {
              slideExtraData = res;
              this.selectedSlideData = {
                ...this.selectedSlide,
                ...slideExtraData.data,
              };
              this.getSiblingsData();
            },
          );
        }
        this.showReplaceVisualPopup =
          this.selectedSlide?.assetId?.includes('newImageReplaceOutput/') &&
          (this.selectedSlide?.isReplaced ||
            this.selectedSlide?.isIconReplaced);
      } else {
        this.getSiblingsData();
      }
    },
    async getSiblingsData() {
      const { category, nodeCount } = this.selectedSlideData;
      this.nodeCount = nodeCount || this.selectedSlideData?.prefs.node;
      this.slideType = category;
      const uniqueID =
        this.selectedSlideData.parent && this.selectedSlideData.parent.unique_id
          ? this.selectedSlideData.parent.unique_id
          : this.selectedSlideData.unique_id;
      const sid =
        this.selectedSlideData?.parent?.sid ||
        this.selectedSlideData?.prefs?.sid;
      this.isLoading = true;
      this.siblings = await getSiblings(uniqueID, sid, this.currentUser);
      this.isLoading = false;
    },
  },
  watch: {
    selectedSlide() {
      this.isLoading = true;
      this.fetchSlideMetaData();
    },
  },
  mounted() {
    this.fetchSlideMetaData();
  },
};
</script>

<style lang="scss" scoped>
.node-count-container {
  height: 100%;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .node-content {
    height: calc(100% - 70px);
    overflow: auto;
    padding: 5px;
    .slide {
      width: calc(50% - 20px);
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      .node-image {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 10px;
        cursor: pointer;

        &.current {
          border: 2.5px solid #21a7e0;
          cursor: default;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .node-count {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
