<template>
  <div class="overview-container">
    <div class="overnight-prezentations-container">
      <div class="header-section">
        <div class="title-section">
          <h2 data-pendo-id="opOverviewStart">
            {{ $t('overnightPresentations.overnightPresentation') }}
          </h2>
          <div class="title-section-subheading">
            {{ $t('overnightPresentations.titleSubHeading') }}<br />
            {{ $t('overnightPresentations.titleSubHeading2') }}
          </div>
        </div>
      </div>
      <v-img
        class="overview-img"
        src="/assets/img/overnight-prezentations/make-your-idea.svg"
      />
      <div class="overview-stats">
        <div class="overview-single-stats">
          <h1>{{ $t('overnightPresentations.2million') }}</h1>
          <p>{{ $t('overnightPresentations.slidesMade') }}</p>
        </div>
        <div class="overview-single-stats">
          <h1>{{ '4.9/5' }}</h1>
          <p>{{ $t('overnightPresentations.customerSatisfaction') }}</p>
        </div>
        <div class="overview-single-stats">
          <h1>{{ '&lt;2%' }}</h1>
          <p>{{ $t('overnightPresentations.revisionRate') }}</p>
        </div>
      </div>
      <div class="submit-req-btn">
        <v-btn
          class="primary-button"
          color="#21a7e0"
          rounded
          id="next-btn"
          width="220"
          height="48"
          :disabled="disableNewReq"
          style="text-transform: none !important"
          @click="submitNewRequest('Overnight Prezentations')"
        >
          {{ $t('overnightPresentations.submitNewRequest') }}
        </v-btn>
      </div>
    </div>
    <div class="how-it-works-container">
      <div class="header-section">
        <div>
          <h2>{{ $t('overnightPresentations.howItWorks') }}</h2>
        </div>
        <a
          :style="{
            display: 'flex',
            'pointer-events': `${loading ? 'none' : 'auto'}`,
          }"
          @click="handleVideoOpen(overnightTutorial)"
        >
          <v-img
            class="watch-play-btn"
            src="/assets/icons/overnight-tutorial.svg"
          ></v-img>
          <div class="watch-tutorial">
            {{ $t('overnightPresentations.viewTutorial') }}
          </div>
        </a>
      </div>
      <div ref="timeline" class="timeline-container">
        <div
          v-for="(content, index) in timeLineCotent"
          :key="index"
          class="timeline-item"
          :class="{ opposite: index % 2 === 0 }"
        >
          <v-card>
            <v-img
              height="200"
              width="300"
              :src="content.image"
              contain
              class="text-white"
            />
            <v-card-title class="timeline-title">
              {{ $t(content.heading) }}
            </v-card-title>
            <v-card-text class="timeline-desc">
              {{ $t(content.description) }}
            </v-card-text>
          </v-card>
          <div class="dot"></div>
        </div>
        <div
          ref="progress"
          class="progress-bar"
          :style="{
            top: timelineHeight / 6 + 'px',
            height: (timelineHeight / 3) * 2 + 'px',
          }"
        >
          <div
            class="progress-indicator"
            :style="{ height: progress + '%' }"
          ></div>
        </div>
      </div>
      <div class="submit-req-btn">
        <v-btn
          class="primary-button"
          color="#21a7e0"
          rounded
          id="next-btn"
          width="220"
          height="48"
          style="text-transform: none !important"
          :disabled="disableNewReq"
          @click="submitNewRequest('How it works')"
        >
          {{ $t('overnightPresentations.submitNewRequest') }}
        </v-btn>
      </div>
    </div>
    <div class="before-and-after-container">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.beforeAndAfter') }}</h2>
          <div class="title-section-subheading">
            {{ $t('overnightPresentations.moveTheSlider') }}
          </div>
        </div>
      </div>
      <div class="container" data-pendo-id="beforeAndAfter">
        <v-carousel
          hide-delimiter-background
          height="auto"
          prev-icon="mdi-chevron-left"
          v-model="activeCarouselItem"
          next-icon="mdi-chevron-right"
        >
          <v-carousel-item
            :class="activeCarouselItem === index && 'v-window-item--active'"
            v-for="(content, index) in sliderImages"
            :key="index"
            eager
          >
            <BeforeAfter
              :value="50"
              :step="0.01"
              :beforeImage="content.beforeImage"
              :afterImage="content.afterImage"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="submit-req-btn mt-10">
        <v-btn
          class="primary-button"
          color="#21a7e0"
          rounded
          id="next-btn"
          width="220"
          height="48"
          style="text-transform: none !important"
          :disabled="disableNewReq"
          @click="submitNewRequest('Before and after')"
          data-pendo-id="submitNewRequest"
        >
          {{ $t('overnightPresentations.submitNewRequest') }}
        </v-btn>
      </div>
    </div>
    <div class="customer-testimonial-container">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.customerTestimonials') }}</h2>
        </div>
      </div>
      <div class="customer-stats">
        <div
          class="customer-test-single"
          v-for="(item, i) in customerTest"
          :key="i"
        >
          <p
            class="customer-test-message"
            v-html="highlightSpecificWords($t(item.message))"
          ></p>
        </div>
      </div>
      <div class="submit-req-btn">
        <v-btn
          class="primary-button"
          color="#21a7e0"
          rounded
          id="next-btn"
          width="220"
          height="48"
          style="text-transform: none !important"
          :disabled="disableNewReq"
          @click="submitNewRequest('Customer testimonials')"
        >
          {{ $t('overnightPresentations.submitNewRequest') }}
        </v-btn>
      </div>
    </div>
    <div class="faqs-container">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.faq') }}</h2>
        </div>
      </div>
      <div class="faq-questions">
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(faq, index) in faqData"
            :key="index"
            @click="handleFAQHandle(faq.question)"
          >
            <v-expansion-panel-header class="faq-header">
              {{ $t(faq.question) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="faq-content"
              v-html="returnMarked($t(faq.answer))"
            >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="submit-req-btn">
        <v-btn
          class="primary-button"
          color="#21a7e0"
          rounded
          id="next-btn"
          width="220"
          height="48"
          style="text-transform: none !important"
          :disabled="disableNewReq"
          @click="submitNewRequest('FAQs')"
        >
          {{ $t('overnightPresentations.submitNewRequest') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import { mapGetters, mapState } from 'vuex';
import {
  TD_FAQTITLE,
  TD_PLACEMENT,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { getFileURL } from '@/utils/calyrex';
import HelpApi from '../../../API/help-api';
import TutorialVideoDetail from '../Help/Tutorials/TutorialVideoDetail.vue';
import { trackOvernightPrezentationsEvents } from '../../common/Analytics/OvernightPrezentationsEvents';
import BeforeAfter from './BeforeAfter.vue';

export default {
  name: 'Overview',
  components: {
    BeforeAfter,
  },
  mixins: [HelpApi],
  data() {
    return {
      customerTest: [
        {
          name: 'Sarah G',
          role: 'overview.oncologyMarketingManager',
          message: 'overview.testimony1',
        },
        {
          name: 'Jennifer Lowry',
          role: 'overview.directorOfCustomerSuccess',
          message: 'overview.testimony2',
        },
        {
          name: 'Uthra Gopalakrishnan',
          role: 'overview.senoirMarketingManager',
          message: 'overview.testimony3',
        },
      ],
      specificWords: ['Christmas', 'Easy', 'countless'],
      activeCarouselItem: 0,
      items: [
        {
          title: 'Step 1',
          description: 'Description of Step 1',
          icon: 'fa fa-check',
        },
        {
          title: 'Step 2',
          description: 'Description of Step 2',
          icon: 'fa fa-check',
        },
        {
          title: 'Step 3',
          description: 'Description of Step 3',
          icon: 'fa fa-check',
        },
        {
          title: 'Step 4',
          description: 'Description of Step 4',
          icon: 'fa fa-check',
        },
      ],
      activeIndex: 2,
      flickityOptions: {
        freeScroll: false,
        draggable: false,
        stepperValue: 2,
        initialIndex: 0,
        groupCells: true,
        wrapAround: true,
        progress: 0,
        pageDots: true,
        prevNextButtons: true,
      },
      timelineHeight: 0,
      timeLineCotent: [
        {
          image: '/assets/img/overnight-prezentations/submit-a-new-request.svg',
          heading: 'overview.submitANewRequest',
          description: 'overview.requestDescription',
        },
        {
          image: '/assets/img/overnight-prezentations/go-to-sleep.svg',
          heading: 'overview.goToSleep',
          description: 'overview.weWorkMagicAtNight',
        },
        {
          image:
            '/assets/img/overnight-prezentations/receive-your-presenatation.svg',
          heading: 'overview.receivePresentation',
          description: 'overview.byNextBusinessDay',
        },
      ],
      sliderImages: [
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-1.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-1.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-2.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-2.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-3.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-3.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-4.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-4.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-5.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-5.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-6.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-6.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-7.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-7.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-8.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-8.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-9.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-9.png',
        },
        {
          beforeImage:
            '/assets/img/overnight-prezentations/preview-image/before-image-10.png',
          afterImage:
            '/assets/img/overnight-prezentations/preview-image/after-image-10.png',
        },
      ],
      faqData: [
        {
          question: 'overview.question1',
          answer: 'overview.answer1',
        },
        {
          question: 'overview.question2',
          answer: 'overview.answer2',
        },
        {
          question: 'overview.question3',
          answer: 'overview.answer3',
        },
        {
          question: 'overview.question4',
          answer: 'overview.answer4',
        },
        {
          question: 'overview.question5',
          answer: 'overview.answer5',
        },
      ],
      progress: 0,
      overnightTutorial: {},
      loading: true,
      tutorialDialog: {
        isOpen: false,
        item: null,
      },
    };
  },
  computed: {
    ...mapGetters('users', ['getOPAccessLevel', 'getOPLimitedBanner']),
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
    disableNewReq() {
      if (
        this.getOPAccessLevel === 'restricted_access' &&
        this.limitsInfoObj?.op?.isLimitCrossed
      ) {
        return true;
      }
      return (
        !!this.getOPLimitedBanner ||
        !this.getOPAccessLevel ||
        ['disabled', 'limited_access'].includes(this.getOPAccessLevel) ||
        (this.getOPAccessLevel === 'restricted_access' &&
          this.limitsInfoObj?.op?.isLimitCrossed)
      );
    },
  },
  async mounted() {
    if (this.$refs.timeline) {
      const rect = this.$refs.timeline.getBoundingClientRect();
      this.timelineHeight = rect.height;
    }
    document
      .getElementById('op-wrapper')
      .addEventListener('scroll', this.onScroll);

    this.overnightTutorial = await this.getTutorialDetails();
    this.loading = false;
  },
  methods: {
    highlightSpecificWords(sentence) {
      const words = sentence.split(/\b/);
      const highlightedWords = words.map((word) => {
        const isSpecific = this.specificWords.some(
          (specWord) => word.toLowerCase() === specWord.toLowerCase(),
        );
        return isSpecific
          ? `<span class="highlighted" style="color:#21a7e0;">${word}</span>`
          : word;
      });

      const highlightedSentence = highlightedWords.join('');

      return `"${highlightedSentence}"`;
    },
    returnMarked(content) {
      return DOMPurify.sanitize(
        `<div class="marked-container">${marked(content)}</div>`,
      );
    },
    handleFAQHandle(question) {
      trackOvernightPrezentationsEvents.OPFAQClick(this.currentUser, {
        [TD_FAQTITLE]: question,
      });
    },
    onScroll() {
      window.requestAnimationFrame(() => {
        const target = this.$refs.progress.getBoundingClientRect();
        const win = document.getElementById('op-wrapper');
        const top = target.top + this.timelineHeight;
        const itemLength =
          // eslint-disable-next-line no-nested-ternary
          win.scrollTop + win.clientHeight <= top
            ? 0
            : win.scrollTop + win.clientHeight - top <=
              win.clientHeight + target.height
            ? win.scrollTop + win.clientHeight - top
            : win.clientHeight + target.height;

        const percentage = Math.round(
          (itemLength * 100) / (win.clientHeight + target.height),
        );
        this.progress = percentage;
      });
    },
    handleVideoOpen(item) {
      console.log('item ->', item);
      this.tutorialDialog.item = item;
      this.tutorialDialog.isOpen = true;
      this.$modal.show(
        TutorialVideoDetail,
        {
          streamVideoURL: this.tutorialDialog.item.videoUrl,
          modalclosed: this.handleVideoClosedFn,
        },
        {
          name: 'TutorialVideoDetail',
          width: '1280px',
          height: '720px',
        },
      );
    },
    async decorateTutorial(tutorial) {
      if (tutorial) {
        tutorial.imageUrl = await this.getSignedFileURL(tutorial?.image_link);
        tutorial.videoUrl = await this.getSignedFileURL(tutorial?.video_link);
      }
      return tutorial;
    },
    async getSignedFileURL(uri) {
      return await getFileURL(
        this.currentUser.username,
        uri,
        this.currentUser.userIp,
      );
    },
    async getTutorialDetails() {
      return await HelpApi.methods
        .getHelpData('tutorial', this.currentUser?.user?.language || 'en')
        .then(
          async (resp) => {
            const opTutorial = resp.data.filter(
              (tutorial) => tutorial.category[0] === 'Overnight Prezentations',
            );
            return await this.decorateTutorial(opTutorial[0]);
          },
          (error) => {
            console.log(error.response);
          },
        );
    },
    submitNewRequest(placement) {
      this.$router.push(
        `/home/overnight-prezentations?tab=newRequest&origin=${placement}`,
      );
      trackOvernightPrezentationsEvents.OPOverviewSubmitNewRequestClick(
        this.currentUser,
        {
          [TD_PLACEMENT]: placement,
        },
      );
    },
  },
  beforeDestroy() {
    const el = document.getElementById('op-wrapper');
    if (el) {
      el.removeEventListener('scroll', this.onScroll);
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.overview-container {
  text-align: start;
  font-family: 'Lato' !important;
  width: 95%;
  margin: 0 auto;
  .overnight-prezentations-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-section {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .title-section-subheading {
        font-size: 16px;
      }
      .red-color {
        font-weight: 700;
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;

        color: #000000;
        margin-bottom: 8px;
      }
    }

    .tutorial-items__wrapper {
      height: 60vh;
      overflow-y: auto;
      padding: 5px;
      width: 100%;
    }
    .overview-img {
      width: 85%;
    }
    .overview-stats {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 85%;
      justify-content: space-between;
      .overview-single-stats {
        // text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 30%;
        height: 85px;
        border-radius: 10px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        h1 {
          margin-top: -6px;
          padding: 0px;
          height: 40%;
        }
        p {
          margin: 0px;
          height: 30%;
          padding: 0px;
        }
      }
    }
    .submit-req-btn {
      margin: 0px 0px 20px 0px;
    }
  }
  .how-it-works-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-section {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .title-section-subheading {
        font-size: 16px;
      }
      .red-color {
        font-weight: 700;
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;

        color: #000000;
        margin-bottom: 8px;
      }
    }

    .tutorial-items__wrapper {
      width: 100%;
    }
    .submit-req-btn {
      margin: 0px 0px 20px 0px;
    }
    .timeline-container {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .timeline-item {
        position: relative;
        width: 50%;

        .dot {
          height: 36px;
          width: 36px;
          position: absolute;
          top: 50%;
          left: 50%;
          border: 2px solid #21a7e0;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          z-index: 3;
        }
      }

      .v-card {
        transform: translateX(calc(50% + 40px));
      }

      .opposite {
        .v-card {
          transform: translateX(calc(-50% - 40px));
        }
      }

      .progress-bar {
        width: 7px;
        background: #d8d8d8;
        position: absolute;

        .progress-indicator {
          width: 100%;
          background: #21a7e0;
        }
      }
      .v-card {
        max-width: 535px;
        width: calc(100% - 40px);
        margin: auto;
        height: 350px;
        padding: 10px;
        margin-bottom: 20px;
        background: #ffffff;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .timeline-title {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          text-align: center;
          color: #000000;
        }
        .timeline-desc {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          text-align: center;
          color: #697077;
        }
      }
    }

    .watch-tutorial {
      font-size: 24px;
      color: #21a7e0;
      align-items: center;
      font-weight: bold;
      padding: 0px 0px 0px 10px;
    }

    .watch-play-btn {
      display: flex;
      width: 28px;
      padding: 0px 37px 0px 0px;
    }

    a {
      text-decoration: none;
    }
  }
  .before-and-after-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-section {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .title-section-subheading {
        font-size: 16px;
      }
      .red-color {
        font-weight: 700;
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;

        color: #000000;
        margin-bottom: 8px;
      }
    }
    .container {
      width: 90%;
      padding: 0 !important;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      margin-bottom: 30px;

      ::v-deep .v-window {
        .v-window__prev,
        .v-window__next {
          background: none;
        }

        .v-window__next {
          transform: translateX(200%);
        }
        .v-window__prev {
          transform: translateX(-200%);
        }

        .v-carousel__controls {
          bottom: -60px;

          .v-carousel__controls__item {
            background: #ffffff;
            box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1),
              inset 1px 2px 2px rgba(0, 0, 0, 0.2);
            color: #ffffff !important;
            width: 16px;
            height: 16px;
            border: 1px solid #d8d8d8;

            &.v-item--active {
              background: #21a7e0;
              box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.1),
                inset 1px 2px 2px rgba(0, 0, 0, 0.2);
              color: #21a7e0 !important;
            }
          }
        }
      }
    }
    .submit-req-btn {
      margin: 0px 0px 20px 0px;
    }
  }
  .customer-testimonial-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-section {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .title-section-subheading {
        font-size: 16px;
      }
      .red-color {
        font-weight: 700;
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;

        color: #000000;
        margin-bottom: 8px;
      }
    }
    .submit-req-btn {
      margin: 0px 0px 20px 0px;
    }
    .customer-stats {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 85%;
      justify-content: space-between;
      .customer-test-single {
        padding: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
        gap: 8px;
        width: 32%;
        min-height: 175px;
        border-radius: 10px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        h3 {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;

          text-align: center;
        }
        // .customer-test-role {
        //   font-family: 'Lato';
        //   font-style: normal;
        //   font-weight: 400;
        //   font-size: 16px;
        //   line-height: 19px;
        //   text-align: end;
        //   color: black;
        // }
        .customer-test-message {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 30px;
          line-height: 32px;
          // display: flex;
          // align-items: flex-end;
          text-align: center;
          color: #697077;
          white-space: pre-wrap;
        }
      }
    }
  }
  .faqs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-section {
      margin: 20px 0px 20px 0px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .title-section-subheading {
        font-size: 16px;
      }
      .red-color {
        font-weight: 700;
      }

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -0.01em;

        color: #000000;
        margin-bottom: 8px;
      }
    }
    .faq-questions {
      margin: 0px 0px 20px 0px;
      .faq-content {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        /* or 21px */

        /* New Palette/Primary/Primary 50 */

        color: #697077;
      }
      .faq-header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        /* identical to box height, or 26px */

        color: #000000;
      }
      ::v-deep .v-expansion-panel {
        box-shadow: $item-box-shadow;
        border-radius: 8px;
        margin-top: 16px;
        padding: 24px 16px;

        &::before {
          box-shadow: none !important;
        }
        &::after {
          border: 0;
        }

        .v-expansion-panel-header {
          font-weight: 400;
          font-size: 20px;
          line-height: 130%;
        }

        .v-expansion-panel-content {
          display: block;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          margin: 1em 24px;
          .marked-container {
            ol {
              margin-bottom: 1em;
            }
            ul {
              margin-bottom: 1em;
            }
            p {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    .submit-req-btn {
      margin: 0px 0px 20px 0px;
    }
  }
  v-card-title {
    text-align: center;
  }
}
</style>
