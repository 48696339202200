<template>
  <div class="reset-password-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <v-form ref="resetPasswordForm" @submit.prevent="onSubmit">
      <v-card class="reset-password-card" flat>
        <v-card-title class="mb-3">
          <span class="headline">{{
            $t('profile.changePasswordDetail.passwordReset')
          }}</span>
        </v-card-title>
        <v-card-subtitle
          v-if="!resendVerificationCodeClicked && emailVerificationCodeSent"
        >
          <img src="/assets/img/verification_code_sent.svg" alt />
          <span class="sub-title">{{
            $t('profile.changePasswordDetail.verificationCodeEmailed')
          }}</span>
        </v-card-subtitle>
        <v-card-subtitle v-if="resendVerificationCodeClicked">
          <v-progress-circular indeterminate color="primary" />
          <span class="sub-title">{{
            $t('profile.changePasswordDetail.emailingVerificationCode')
          }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <div class="label">
                  {{ $t('profile.changePasswordDetail.userName') }}
                </div>
                <v-text-field
                  :rules="usernameRules"
                  v-model="username"
                  color="#000"
                  id="username-field"
                  :disabled="true"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="label">
                  {{ $t('profile.changePasswordDetail.code') }}
                </div>
                <v-text-field
                  :label="
                    $t('profile.changePasswordDetail.enterVerificationCode')
                  "
                  :rules="verificationCodeRules"
                  v-model="verificationCode"
                  color="#000"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <div class="label">
                  {{ $t('profile.changePasswordDetail.newPassword') }}
                </div>
                <v-form ref="passwordControl">
                  <v-text-field
                    v-model="password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-1"
                    :label="
                      $t('profile.changePasswordDetail.createNewPassword')
                    "
                    single-line
                    @click:append="show1 = !show1"
                    color="#000"
                  />
                </v-form>
              </v-col>
              <div
                v-if="password && passwordRequirements.length"
                class="password-errors-box"
              >
                <div
                  v-for="(value, index) in passwordErrors"
                  :key="index"
                  class="password-wrapper"
                >
                  <span :class="checkError(value) ? 'visibility' : ''"
                    >&#10003;</span
                  >
                  <p :class="checkError(value) ? 'password-error' : ''">
                    {{ $t(value) }}
                  </p>
                </div>
              </div>
              <v-col cols="12" sm="12" md="12">
                <div class="label">
                  {{ $t('profile.changePasswordDetail.confirmNewPassword') }}
                </div>
                <v-form ref="confirmPasswordControl">
                  <v-text-field
                    v-model="confirmPassword"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="confirmPasswordRules"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-1"
                    :label="
                      $t('profile.changePasswordDetail.confirmNewPassword')
                    "
                    single-line
                    @click:append="show2 = !show2"
                    color="#000"
                  />
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="mb-2 actions">
            <v-btn
              rounded
              type="submit"
              color="#21a7e0"
              min-width="333"
              height="50"
              :loading="isSendingResetLink"
              :disabled="
                !verificationCode ||
                !password ||
                !confirmPassword ||
                !password ||
                !confirmPassword ||
                !(password && password.length >= 8) ||
                !(confirmPassword === password) ||
                !!passwordRequirements.length
              "
              class="mb-4"
              name="reset-password-btn"
            >
              {{ $t('profile.changePasswordDetail.resetPassword') }}
            </v-btn>
            <div
              class="verification-code"
              style="cursor: pointer"
              @click="resendVerificationCode"
            >
              <p
                class="primary--text"
                :class="{ 'disable-text': resendVerificationCodeClicked }"
              >
                {{ $t('profile.changePasswordDetail.resendVerificationCode') }}
              </p>
            </div>
          </div>
        </v-card-actions>
        <div v-if="error !== ''" class="error">
          {{ error }}
        </div>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import PasswordSetDetail from './PasswordSetDetail.vue';
import { MatomoAnalyticsHandler } from '../../common/Analytics/MatomoAnalyticsHandler';
import { isEmojiExist } from '../../utils';

export default {
  name: 'ResetPasswordDetail',
  props: {
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      valid: true,
      error: '',
      username: '',
      usernameRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => !!v || this.$t('warnings.userNameRequired'),
      ],
      verificationCode: '',
      verificationCodeRules: [
        (v) => !!v || this.$t('warnings.verificationCodeRequired'),
      ],
      show1: false,
      show2: false,
      password: '',
      confirmPassword: '',
      passwordErrors: [
        'warnings.eightCharacters',
        'warnings.oneLetter',
        'warnings.oneNumber',
        'warnings.oneSpecialCharacter',
      ],
      passwordRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        () =>
          this.isPasswordMatched('password') ||
          this.$t('warnings.passwordMustMatch'),
      ],
      confirmPasswordRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        () =>
          this.isPasswordMatched('confirmPassword') ||
          this.$t('warnings.passwordMustMatch'),
      ],
      isSent: false,
      isSendingResetLink: false,
      resendVerificationCodeClicked: false,
      emailVerificationCodeSent: true,
    };
  },
  watch: {
    isSent() {
      if (this.isSent) {
        this.handleClose();
        this.handlePasswordSet();
      }
    },
    userName(val = '') {
      this.username = val.toLowerCase();
    },
    confirmPassword(value) {
      if (value) {
        this.$refs.passwordControl.resetValidation();
        this.$refs.passwordControl.validate();
      }
    },
    password(value) {
      if (value) {
        this.$refs.confirmPasswordControl.resetValidation();
        this.$refs.confirmPasswordControl.validate();
      }
    },
  },
  computed: {
    passwordRequirements() {
      const errors = [];
      if (this.password && this.password.length < 8)
        errors.push('warnings.eightCharacters');
      if (this.password && !/[A-Za-z]{1}/.test(this.password))
        errors.push('warnings.oneLetter');
      if (this.password && !/\d/.test(this.password))
        errors.push('warnings.oneNumber');
      if (this.password && !/[^A-Za-z0-9]/.test(this.password))
        errors.push('warnings.oneSpecialCharacter');
      return errors;
    },
  },
  methods: {
    handlePasswordSet() {
      this.$modal.show(
        PasswordSetDetail,
        {},
        {
          name: 'PasswordSetDetail',
          width: '528px',
          height: '589px',
        },
      );
    },
    handleClose() {
      this.$modal.hide('ResetPasswordDetail');
    },
    checkError(err) {
      return this.passwordRequirements.includes(err);
    },
    isPasswordMatched(control) {
      if (
        (control === 'password' && this.confirmPassword) ||
        (control === 'confirmPassword' && this.password)
      ) {
        return this.password === this.confirmPassword;
      }
      return true;
    },
    async onSubmit() {
      // if (!this.$refs.forgotPasswordForm.validate()) return;

      this.isSendingResetLink = true;
      this.username = `${this.username.trim()}`;
      try {
        Auth.forgotPasswordSubmit(
          this.username,
          this.verificationCode,
          this.password,
        )
          .then((data) => {
            MatomoAnalyticsHandler.passwordChanged(this.username);
            this.error = '';
            this.isSent = data;
            this.isSendingResetLink = false;
            this.$router.push('/signin');
            this.handleClose();
          })
          .catch((err) => {
            this.isSendingResetLink = false;
            this.error = err.message;
          });
      } catch (err) {
        this.isSendingResetLink = false;
        console.log(err);
      }
    },
    async resendVerificationCode() {
      this.resendVerificationCodeClicked = true;
      this.username = `${this.username.trim()}`;
      Auth.forgotPassword(this.username)
        .then((data) => {
          this.resendVerificationCodeClicked = false;
          console.log(data);
          this.error = '';
        })
        .catch((err) => {
          this.resendVerificationCodeClicked = false;
          console.error(err);
          this.error = err.message;
        });
    },
  },
  mounted() {
    this.username = this.userName;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.reset-password-wrapper {
  width: 100%;
  height: 100%;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .v-form {
    width: 100%;
    height: 100%;
  }

  .reset-password-card {
    padding: 30px 140px;
    position: relative;
    width: 100%;
    height: 100%;

    .label {
      text-align: left;
      font-size: 20px;
      font-weight: normal;
      color: black;
    }

    .v-card__title,
    .v-card__subtitle {
      text-align: left;
    }

    .v-card__subtitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        margin-top: 10px;
        color: #21a7e0;
      }
    }

    .headline {
      font-size: 24px;
      font-weight: bold;
    }

    .sub-title {
      font-size: 20px;
      font-weight: normal;
    }
    .v-card__actions {
      justify-content: center;

      button {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: none;
        color: white;
        margin-bottom: 16px;
      }

      a {
        text-decoration: none;
      }

      .verification-code {
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #21a7e0;
      }

      .disable-text {
        pointer-events: none !important;
        color: rgba(0, 0, 0, 0.12) !important;
      }
    }

    .error {
      text-align: center;
      font-weight: bold;
    }
  }
}
.v-progress-circular {
  color: #21a7e0 !important;
  margin-right: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.password-errors-box {
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  font-size: 14px;
  left: 1%;
  padding: 5px;
  position: absolute;
  top: 50%;
  .password-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    .visibility {
      visibility: hidden;
    }
    span {
      font-size: 18px;
      font-weight: 500;
    }
    .password-error {
      color: red;
    }
    p {
      margin-bottom: 0px;
    }
  }
}
.password-errors-box::after {
  border: 10px solid transparent;
  border-left: 10px solid white;
  clear: both;
  content: '';
  position: absolute;
  right: -20px;
  visibility: visible;
  top: 40px;
}
::v-deep .theme--light.v-messages {
  color: red !important;
}
</style>
